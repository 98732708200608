import React, { useState, useEffect } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import {
  CustomPagination,
  TableCustom,
  TextAreaInput,
} from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'ISSUE REPORTED DATE', rowspan: 1, colspan: 1 },
    { text: 'TYPE OF COMPLAINT', rowspan: 1, colspan: 1 },
    { text: 'CUSTOMER NAME', rowspan: 1, colspan: 1 },
    { text: 'PART NUMBER', rowspan: 1, colspan: 1 },
    { text: 'PART NAME', rowspan: 1, colspan: 1 },
    { text: 'DEFECT REPORTED', rowspan: 1, colspan: 1 },
    { text: 'TARGET DATE', rowspan: 1, colspan: 1 },
    { text: 'ACTUAL CLOSURE DATE', rowspan: 1, colspan: 1 },
    { text: 'SHORT TERM ACTION', rowspan: 1, colspan: 1 },
    { text: 'ROOT CAUSE', rowspan: 1, colspan: 1 },
    { text: 'ACTION TAKEN', rowspan: 1, colspan: 1 },
    { text: 'STATUS', rowspan: 1, colspan: 1 },
    { text: 'IN HOUSE REJECTION', rowspan: 1, colspan: 1 },
    { text: 'EFFECTIVE QTY', rowspan: 1, colspan: 1 },
    { text: 'JAN', rowspan: 1, colspan: 1 },
    { text: 'FEB', rowspan: 1, colspan: 1 },
    { text: 'MAR', rowspan: 1, colspan: 1 },
    { text: 'APR', rowspan: 1, colspan: 1 },
    { text: 'MAY', rowspan: 1, colspan: 1 },
    { text: 'JUN', rowspan: 1, colspan: 1 },
    { text: 'JUL', rowspan: 1, colspan: 1 },
    { text: 'AUG', rowspan: 1, colspan: 1 },
    { text: 'SEP', rowspan: 1, colspan: 1 },
    { text: 'OCT', rowspan: 1, colspan: 1 },
    { text: 'NOV', rowspan: 1, colspan: 1 },
    { text: 'DEC', rowspan: 1, colspan: 1 },
    { text: 'DEFECT PHOTO', rowspan: 1, colspan: 1 },
    { text: 'SUPPORTING DOCUMENTS', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

//const filterOptions = [{ value: 'customer_name', name: 'Customer Name' }];
//const newFilterOptions = [{ value: 'defect_name', name: 'Defect Reported' }];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [contentType, setContentType] = useState('');
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const [filterDefectsReported, setFilterDefectsReported] = useState<any>([]);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const customer = useQuery('customer');
  const defect = useQuery('defects');
  const [all, setAll] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer.customer_name,
          }));
          setFilterCustomer([
            { value: 'NONE', name: 'NONE' },
            ...formattedData,
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((defect: any) => ({
            name: defect.defect_name,
            value: defect.defect_name,
          }));
          setFilterDefectsReported([
            { value: 'NONE', name: 'NONE' },
            ...formattedData,
          ]);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/inHouseComplaintRegisterRoutes/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&customer=${customer}&defects=${defect}&all=${all}`,
      )
      .catch((err) => {
        console.log(err);
      });

    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomerConcern = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/inHouseComplaintRegisterRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    filter,
    customer,
    defect,
    all,
  ]);
  return (
    <>
      <ChildHeader text="IN-HOUSE COMPLAINT REGISTER">
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? 'Hide' : 'Save'}
        />
        <AddButton
          onClick={() => setIsModal(true)}
          label="NEW IN-HOUSE COMPLAINT"
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        {/*<SearchBar label="Search By Customer Name" />*/}
        <FilterBy
          //sx={{ width: 500 }}
          name="customer"
          label="Filter By Customer"
          options={filterCustomer}
        />
        <FilterBy
          //sx={{ width: 500 }}
          name="defects"
          label="Filter By Defects Reprted"
          options={filterDefectsReported}
        />
      </SearchFilterContainer>

      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">
                      {item.issue_reported_date &&
                        formatDate(item.issue_reported_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.type_of_complaint}
                    </TableCell>
                    <TableCell align="center">
                      {item.customer_id?.customer_name}
                    </TableCell>
                    <TableCell align="center">
                      {item.part_id?.part_number}
                    </TableCell>
                    <TableCell align="center">
                      {item.part_id?.part_name}
                    </TableCell>
                    <TableCell align="center">
                      {item.defect_id?.defect_name}
                    </TableCell>
                    <TableCell align="center">
                      {item.target_date && formatDate(item.target_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.actual_closure_date &&
                        formatDate(item.actual_closure_date)}
                    </TableCell>
                    <TableCell align="center">
                      <TextAreaInput value={item.short_term_action} />
                    </TableCell>
                    <TableCell align="center">
                      <TextAreaInput value={item.root_cause} />
                    </TableCell>
                    <TableCell align="center">
                      <TextAreaInput value={item.action_taken} />
                    </TableCell>
                    <TableCell align="center">{item.status}</TableCell>
                    <TableCell align="center">
                      {item.in_house_rejection}
                    </TableCell>
                    <TableCell align="center">{item.effective_qty}</TableCell>
                    {[
                      'jan',
                      'feb',
                      'mar',
                      'apr',
                      'may',
                      'jun',
                      'jul',
                      'aug',
                      'sep',
                      'oct',
                      'nov',
                      'dec',
                    ].map((month: any) => {
                      return (
                        <TableCell
                          style={{
                            ...(item[month] &&
                              item[month].toLowerCase() === 'repeated' && {
                                background: '#e03131',
                              }),
                            ...(item[month] &&
                              item[month].toLowerCase() === 'not repeated' && {
                                background: '#2f9e44',
                              }),
                            width: '50px',
                          }}
                          align="center"
                        ></TableCell>
                      );
                    })}
                    <TableCell align="center">
                      {
                        <ViewIconButton
                          tooltipTitle="view"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('image');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        <ViewIconButton
                          tooltipTitle="view"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('file');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setComplaintId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteCustomerConcern(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="IN-HOUSE COMPLAINT REGISTER"
      >
        <Create id={complaintId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} contentType={contentType} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
