import { Box } from '@mui/system';
import SelectNpdProject from '../components/SelectNpdProject';

const List = () => {
  return (
    <Box>
      <SelectNpdProject link={'/common/createNpdTimePlan'} />
    </Box>
  );
};

export default List;
