import { BsFillPersonVcardFill } from 'react-icons/bs';
import { FcDepartment } from 'react-icons/fc';
import { MdConfirmationNumber, MdStars } from 'react-icons/md';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { competencyMatrixConstant } from './completencyMatrix/helpers/constant';
import { moduleConstant } from './designation/helpers/constant';
import { moduleConstant as jdConstant } from './jd/helpers/constant';
const tiles = [
  {
    sNo: 1,
    link: `${jdConstant.listRoute}`,
    isLink: true,
    icon: <BsFillPersonVcardFill size="5rem" color="#343a40" />,
    p: `${jdConstant.tilesPHeading}`,
    span: `${jdConstant.tilesSpanHeading}`,
    isIcon: true,
  },
  {
    sNo: 2,
    link: `${competencyMatrixConstant.listRoute}`,
    isLink: true,
    icon: <MdConfirmationNumber size="5rem" color="#343a40" />,
    p: `${competencyMatrixConstant.tilesPHeading}`,
    span: `${competencyMatrixConstant.tilesSpanHeading}`,
    isIcon: true,
  },
  {
    sNo: 3,
    link: `${moduleConstant.listRoute}`,
    isLink: true,
    icon: <MdStars size="5rem" color="#087f5b" />,
    p: `${moduleConstant.tilesPHeading}`,
    span: `${moduleConstant.tilesSpanHeading}`,
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/departmentList',
    isLink: true,
    clauseNo: 5,
    p: 'MASTER LIST OF',
    span: 'DEPARTMENT',
    icon: <FcDepartment size="5rem" color="#135089" />,
    isIcon: true,
  },
];

const Home = () => {
  return (
    <>
      <ChildHeader text="Job Description" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default Home;
