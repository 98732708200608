import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../../../components/common/autocomplete/AutocompleteGridmui';
import { useNavigate } from 'react-router-dom';
import { competencyMatrixConstant } from '../helpers/constant';

const ModalInput = ({
  setIsModal,
}: {
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setMoudleData] = useState<any>({});
  const [render, setRender] = useState(false);
  const [designationData, setDesignationtData] = useState([]);
  const navigate = useNavigate();

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `Submit`,
    `Are you sure you want to save this?`,
  );
  const [departmentData, setDepartmentData] = useState<any>([]);
  useEffect(() => {
    axios
      .get(`/api/department/department/departmentForAutoComplete`)
      .then((res) => {
        setDepartmentData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/autocompleteRoute/getDesignation`)
      .then((res) => {
        setDesignationtData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setMoudleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    if (!moduleData?.designation_id || !moduleData?.title)
      return alert('please enter both value');
    const ans = await confirmSubmit();
    if (!ans) return;
    axios
      .get(
        `/api/${competencyMatrixConstant.api}/duplicatepart/${moduleData?.designation_id?._id}/${moduleData?.department_id?._id}`,
      )
      .then((res) => {
        if (res?.data?.isDuplicate === true) {
          alert(
            'Designation exists already. please choose another Designation',
          );
        } else {
          navigate(
            `${competencyMatrixConstant.createRoute}?title=${moduleData?.title}&designation_id=${moduleData?.designation_id?._id}&designation=${moduleData?.designation_id?.designation}&department_id=${moduleData?.department_id?._id}&departmentName=${moduleData?.department_id?.department_name}`,
          );
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'Title'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title || ''}
        onChange={handleData}
      />
      <AutocompleteGridmui
        label={'Designation'}
        id={'designation'}
        option_name={'designation'}
        value={moduleData.designation_id || ''}
        arrayofObj={designationData}
        onChange={(e, value) => {
          moduleData.designation_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <AutocompleteGridmui
        label={'Department'}
        id={'department'}
        option_name={'department_name'}
        arrayofObj={departmentData}
        value={moduleData.department_id || ''}
        onChange={(e, value) => {
          moduleData.department_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton label={`submit`} type="button" onClick={handleSumbit} />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
