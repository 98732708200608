import React, { useEffect, useState } from 'react';
import { Box, TableBody } from '@mui/material';
import {
  CustomPagination,
  PersitedDatePicker,
  SearchBar,
  TableCustom,
} from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { AddButton } from '../../../components/common/button/CustomButtons';
import axios from 'axios';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import CreateDocument from './components/CreateDocument';
import { useQuery } from '../../../hooks/UseQuery';
import ListTableRow from './components/ListTableRow';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import useDebounce from '../../../hooks/useDebounce';

interface IData {
  _id?: string;
  store_part_id?: any;
  date_of_receiving?: Date;
  qty: number;
  balance_qty: string;
}
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'PART NO.', rowspan: 1, colspan: 1 },
    { text: 'MRN NO.', rowspan: 1, colspan: 1 },
    { text: 'RI STORE LOCATION', rowspan: 1, colspan: 1 },
    { text: 'DATE OF RECEIVING', rowspan: 1, colspan: 1 },
    { text: 'VENDOR', rowspan: 1, colspan: 1 },
    { text: 'QTY', rowspan: 1, colspan: 1 },
    { text: 'BALANCE QTY', rowspan: 1, colspan: 1 },
    { text: 'INS. REPORT', rowspan: 1, colspan: 1 },
    { text: 'STATUS', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [render, setRender] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const page = useQuery('page') || 1;
  const date = useQuery('date');
  const search = useQuery('search');
  const [totalPages, setTotalPages] = useState(0);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this document?'
  );
  const [DialogAssign, confirmAssign] = useConfirm(
    'ASSIGN',
    'Are you sure you want to assign this document?'
  );
  const fetchData = () => {
    axios
      .get(
        `/api/storeReceiveRegister?page=${page}&search=${search}&date=${date}`
      )
      .then((res) => {
        setData(res?.data?.result);
        setTotalPages(res?.data?.pages);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useDebounce(fetchData, 500, [render, page, isOpenPopup, search, date]);
  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`/api/storeReceiveRegister/${id}`)
      .then((res) => {
        if (res.data) {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const assignRegister = async (id: string) => {
    const ans = await confirmAssign();
    if (!ans) return;
    axios
      .patch(`/api/storeReceiveRegister/${id}?isAssign=true`)
      .then((res) => {
        if (res.data) {
          alert('document update successfully');
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box>
      <ChildHeader text="RECEIVE REGISTER">
        <AddButton label="New Entry" onClick={() => setIsOpenPopup(true)} />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <PersitedDatePicker label="Search by month" views={['year', 'month']} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((document: IData, index: number) => (
              <React.Fragment key={document?._id}>
                <ListTableRow
                  {...{
                    document,
                    index,
                    setId,
                    setIsOpenPopup,
                    assignRegister,
                    deleteDocument,
                  }}
                />
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <ModalCustom
        openModal={isOpenPopup}
        closeModal={() => {
          setId(null);
          setIsOpenPopup(false);
        }}
        title="CREATE NEW ENTRY"
      >
        <CreateDocument setIsOpenPopup={setIsOpenPopup} id={id} setId={setId} />
      </ModalCustom>
      <CustomPagination totalPage={totalPages} />
      <DialogDelete isSubmitContent={false} />
      <DialogAssign isSubmitContent={false} />
    </Box>
  );
};

export default List;
