import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import {
  CustomButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import TController from './components/create/TController';
import useConfirm from '../../../../components/common/useConfirm';

const Create = () => {
  const { id } = useParams();
  const [moduleData, setModuleData] = useState<any>({
    moduleDate: new Date(),
    revision_history: [],
    specs: [],
    image: [],
  });
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const [DialogAddRevRow, confirmAddRevRow] = useConfirm(
    'Add Revision Row',
    'Are you sure you want to add rev?'
  );
  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/sstPlatingStdRoute/${id}`)
      .then((res) => {
        setModuleData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const onSubmit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      axios
        .put(`/api/sstPlatingStdRoute/${id}`, moduleData)
        .then((res) => {
          if (res?.data) {
            alert('updated successfull');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const addRevisionRow = async () => {
    const ans = await confirmAddRevRow();
    if (!ans) return;
    const value = { ...moduleData };
    value?.revision_history?.push({});
    setModuleData(value);
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="Plating Standard">
          <a target={'_blank'} href={moduleData.image && moduleData.image[0]}>
            <CustomButton icon={<AiFillEye />} sx={{ marginRight: '1rem' }}>
              VIEW DRAWING
            </CustomButton>
          </a>
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={addRevisionRow}
              >
                ADD REV ROWS
              </CustomButton>
              <SubmitButton label="Submit" onClick={() => onSubmit()} />
            </>
          )}
        </ChildHeader>
        <TController {...{ moduleData, isView }} />
      </Box>
      <DialogSubmit />
      <DialogAddRevRow />
    </Box>
  );
};

export default Create;
