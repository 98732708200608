import { useState } from 'react';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';

interface IProps {
  moduleData: any;
  rowIdx: number;
  month: string;
  planType: string;
  monthIdx: number;
  phase: any;
}
const MonthDoubleClick = ({
  moduleData,
  rowIdx,
  month,
  planType,
  monthIdx,
  phase,
}: IProps) => {
  const [render, setRender] = useState(false);
  const colorCycle = ['#e9c46a', '#457b9d', '#f4a261', '#2a9d8f']; // Light yellow, blue, orange, green

  const pColor = colorCycle[monthIdx % colorCycle.length];

  const handleDoubleClick = (event: any) => {
    if (event.detail === 2) {
      if (!moduleData[phase][rowIdx][month][monthIdx]) {
        moduleData[phase][rowIdx][month][monthIdx] = {
          P: false,
          A: false,
        };
      }
      const bool = moduleData[phase][rowIdx][month][monthIdx][planType];
      moduleData[phase][rowIdx][month][monthIdx][planType] = !bool
        ? true
        : false;
      setRender((prev) => !prev);
    }
  };
  return (
    <td
      style={{
        padding: '0px',
        margin: '0px',
        minWidth: '100px',
      }}
      onClick={(e) => handleDoubleClick(e)}
    >
      <InteractiveIcon
        pColor={pColor}
        planType={planType}
        active={moduleData[phase][rowIdx]?.[month]?.[monthIdx]?.[planType]} // Pass active prop
        isYellow={moduleData[phase][rowIdx]?.[month]?.[monthIdx]?.[planType]}
      >
        {moduleData[phase][rowIdx]?.[month]?.[monthIdx]?.[planType] && planType}
      </InteractiveIcon>
    </td>
  );
};

export default MonthDoubleClick;
