import React, { useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TableCustom } from '../../../../../../components/common';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../../components/common/formatDate';
import CustomUploadButton from '../../../../../../components/common/button/CustomUploadButton';
import ModalCustom from '../../../../../../components/common/Modal';
import ViewFile from './ViewFile';

interface IProps {
  deleteDocument: (id: string) => void;
  uploadFile: (e: any, id: string, fileType: string) => void;
  tableData: {
    [key: string]: string;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Desc',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'INSPECTION REPORT DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'STATUS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'UPLOAD IAI DOCUMENTS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'UPLOAD VENDOR DOCUMENTS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'VIEW',
      colspan: 1,
      rowspan: 1,
    },
  ],
];
const SupervisorTable = ({ deleteDocument, tableData, uploadFile }: IProps) => {
  const navigate = useNavigate();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const idRef = useRef('');
  const fileTypeRef = useRef('');

  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">
                  {document?.plating_id?.part_name}
                </TableCell>
                <TableCell align="center">
                  {formatDate(document.inspection_date)}
                </TableCell>
                <TableCell align="center">
                  {document?.report_prepared_by}
                </TableCell>

                <TableCell align="center">
                  {document?.isSubmitted ? (
                    <div
                      style={{
                        fontSize: '1.4rem',
                        outline: 'none',
                        padding: '5px',
                        textAlign: 'center',
                        width: '200px',
                        margin: 'auto',
                        textTransform: 'uppercase',
                        ...(document.is_admin_request === 'pending' && {
                          backgroundColor: '#FDD835',
                        }),
                        ...(document.is_admin_request === 'accepted' && {
                          backgroundColor: '#2b8a3e',
                          color: '#fff',
                        }),
                        ...(document.is_admin_request === 'rejected' && {
                          backgroundColor: '#f03e3e',
                          color: '#fff',
                        }),
                        ...(document.is_admin_request === 'reject' && {
                          backgroundColor: '#f03e3e',
                          color: '#fff',
                        }),
                      }}
                    >
                      {document.is_admin_request === 'pending' &&
                        'pending for approval'}
                      {document.is_admin_request === 'accepted' && 'accepted'}
                      {document.is_admin_request === 'rejected' && 'Sent back'}
                      {document.is_admin_request === 'reject' && 'rejected'}
                    </div>
                  ) : (
                    <div
                      style={{
                        backgroundColor: '#FDD835',
                        fontSize: '1.4rem',
                        outline: 'none',
                        padding: '5px',
                        textAlign: 'center',
                        width: '200px',
                        margin: 'auto',
                      }}
                    >
                      {document.isSubmitted ? 'SUBMITTED' : 'DRAFT'}
                    </div>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Stack
                    direction={'row'}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CustomUploadButton
                      multiple={true}
                      accept="image/png,image/jpeg,image/jpg,application/pdf"
                      id="upload-iai-documents"
                      onChange={(e) => {
                        uploadFile(e, document._id, 'file1');
                      }}
                    />
                    {document.file1?.length > 0 && (
                      <>
                        <ViewIconButton
                          tooltipTitle="view"
                          sx={{ marginLeft: '1.5rem' }}
                          onClick={() => {
                            idRef.current = document._id;
                            fileTypeRef.current = 'file1';
                            setIsOpenPopup(true);
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack
                    direction={'row'}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CustomUploadButton
                      id="upload-vendor-documents"
                      accept="image/png,image/jpeg,image/jpg,application/pdf"
                      multiple={true}
                      onChange={(e) => {
                        uploadFile(e, document._id, 'file2');
                      }}
                    />
                    {document.file2?.length > 0 && (
                      <>
                        <ViewIconButton
                          tooltipTitle="view"
                          sx={{ marginLeft: '1.5rem' }}
                          onClick={() => {
                            idRef.current = document._id;
                            fileTypeRef.current = 'file2';
                            setIsOpenPopup(true);
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/sst/plating/inspection/report/create/${document?.plating_id?._id}/${document?._id}?isView=true&partName=${document?.plating_id?.part_name}`
                      )
                    }
                  />
                  {!document?.isSubmitted &&
                    (document?.is_admin_request !== 'rejected' ||
                      document?.is_admin_request !== 'reject') && (
                      <>
                        <EditIconButton
                          tooltipTitle="edit"
                          onClick={() =>
                            navigate(
                              `/common/sst/plating/inspection/report/create/${document?.plating_id?._id}/${document?._id}?isView=false&partName=${document?.plating_id?.part_name}`
                            )
                          }
                        />
                        <DeleteIconButton
                          tooltipTitle="delete"
                          onClick={() => deleteDocument(document?._id)}
                        />
                      </>
                    )}
                  {document?.is_admin_request === 'rejected' && (
                    <>
                      <EditIconButton
                        tooltipTitle="edit"
                        onClick={() =>
                          navigate(
                            `/common/sst/plating/inspection/report/create/${document?.plating_id?._id}/${document?._id}?isView=false`
                          )
                        }
                      />
                      <DeleteIconButton
                        tooltipTitle="delete"
                        onClick={() => deleteDocument(document?._id)}
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <ViewFile id={idRef.current} fileType={fileTypeRef.current} />
      </ModalCustom>
    </React.Fragment>
  );
};

export default SupervisorTable;
