export const listConstant = {
  childHeader: 'GAUGE MSA plan list',
  addButtonLable: 'New GAUGE MSA',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'GAUGE MSA plan list',
  theadHeader: 'GAUGE Plan for MSA',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'GAUGE MSA STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'gaugeMsaPlanRoute',
  gettableDataurl: 'gaugeMsaPlanRoute/monthly',
  getPlanByid: 'gaugeMsaPlanRoute',
  createPlanByid: 'gaugeMsaPlanRoute',
  updatePlanByid: 'gaugeMsaPlanRoute',
  sync: 'gaugeMsaPlanRoute/handleSync',
  getAllCheckList: 'gaugeMsaPlanRoute/getAllCheckList',
  checkListStatus: 'gaugeMsaPlanRoute/handleStatus',
  checklistUpload: 'gaugeMsaPlanRoute/handleUpload',
  checklistDeleteImage: 'gaugeMsaPlanRoute/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/gaugeMsaPlanCreate',
};
