import { useState } from 'react';
import { SearchBar } from '../../../../components/common/SearchBar';
import FilterBy from '../../../../components/common/FilterBy';
import { SearchFilterContainer } from '../../../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import ModalCustom from '../../../../components/common/Modal';
import {
  AddButton,
  CustomButton,
} from '../../../../components/common/button/CustomButtons';
import TableCustom from '../../../../components/common/customTable/TableCustom';
import useDebounce from '../../../../hooks/useDebounce';
import React from 'react';
import formatDate from '../../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
} from '../../../../components/common/button/CustomIconButton';

import { useQuery } from '../../../../hooks/UseQuery';
import CustomPagination from '../../../../components/common/CustomPagination';
import useConfirm from '../../../../components/common/useConfirm';
import AddEditPart from './components/EditModule';
import { RiFileExcel2Line } from 'react-icons/ri';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../../components/common/Loader';
import { processExcelFile } from './components/ExcelUpload';

const filterOptions = [
  { value: 'part_name', name: 'A-Z' },
  { value: 'createdAt', name: 'Most Recent First' },
];

const tableHeaderObj = [
  [
    { text: 'Sr. No.', rowspan: 1, colspan: 1 },
    { text: 'Assly Part No.', rowspan: 1, colspan: 1 },
    { text: 'Desc.', rowspan: 1, colspan: 1 },
    { text: 'Plating Part Number', rowspan: 1, colspan: 1 },
    { text: 'Appearance', rowspan: 1, colspan: 1 },
    { text: 'Plating Type', rowspan: 1, colspan: 1 },
    { text: 'Plating Thickness Requirement', rowspan: 1, colspan: 1 },
    { text: 'Thread Gauge Requirement 1', rowspan: 1, colspan: 1 },
    { text: 'Thread Gauge Requirement 2', rowspan: 1, colspan: 1 },
    { text: 'Thread Gauge Requirement 3', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const ListModule = () => {
  const [data, setData] = useState<any>([]);
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [partId, setPartId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to DELETE this Document?'
  );
  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/sstPlatingRoute?page=${page}&sortBy=${
          filter || 'part_name'
        }&search=${search}&perPage=${10}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  const deletePart = async (id: string) => {
    const isDelete = await confirmDelete();
    if (!isDelete) {
      return;
    }
    await axios.delete(`/api/sstPlatingRoute/${id}`);
    setRender((prev) => !prev);
  };

  useDebounce(fetchData, 1000, [page, search, render, filter, isNewPartModal]);

  const handleExcelUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;

    try {
      setIsLoading(true);
      const file = e.target.files[0];
      
      // Validate file type
      if (!file.name.match(/\.(xlsx|xls)$/)) {
        throw new Error('Please upload an Excel file (.xlsx or .xls)');
      }

      // Process Excel file using our utility function
      const processedData = await processExcelFile(file);
      
      // Send to backend
      const response = await axios.post('/api/sstPlatingRoute/bulk', {
        documents: processedData
      });

      // Show success message
      window.alert(`Successfully uploaded ${response.data.count} records`);
      
      // Reset file input
      e.target.value = '';
      
      // Refresh the list
      setRender(prev => !prev);
    } catch (error:any) {
      console.error('Upload failed:', error);
      window.alert(error.message || 'Failed to upload Excel file. Please check the file format and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // const handleMultiplePipeImport = (e: any) => {
  //   window.alert(
  //     'Ensure Valid Numeric / String Input, avoid unsolved numerical values. Exp: 810+1.0 (correct way:811.0) else it will discard those entry.'
  //   );
  //   setIsLoading(true);

  //   const formData = new FormData();
  //   formData.append('file', e.target.files[0], 'pipe.xlsx');
  //   axios
  //     .post(`/api/excelUpload/pipe`, formData)
  //     .then((res) => {
  //       if (!res.data.isExcel) {
  //         window.alert(
  //           'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.'
  //         );
  //       } else {
  //         window.alert(res.data.msg);
  //       }
  //       setIsLoading(false);
  //       setRender((prev) => !prev);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       if (err.response.data.err) {
  //         window.alert(err.response.data.err);
  //       } else {
  //         window.alert(
  //           'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.'
  //         );
  //       }
  //     });
  // };
  return (
    <>
      <Loader loader={isLoading} />

      <ChildHeader text="Plating List">
        {/* <CustomUploadButton
          lable={'Upload Excel'}
          onChange={handleMultiplePipeImport}
        /> */}
        <CustomUploadButton
          lable="Upload Excel"
          onChange={handleExcelUpload}
          accept=".xlsx,.xls"
        />
        <AddButton
          onClick={() => setIsNewPartModal(true)}
          label="New Plating"
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search  Desc or Part Number " />
        <FilterBy label="Sort" options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    {item?.assly_part_number}
                  </TableCell>
                  <TableCell align="center">{item?.part_name}</TableCell>
                  <TableCell align="center">{item?.part_number}</TableCell>
                  <TableCell align="center">{item?.appearance}</TableCell>
                  <TableCell align="center">{item?.plating_type}</TableCell>
                  <TableCell align="center">
                    {item?.plating_thickness_requirement}
                  </TableCell>
                  <TableCell align="center">
                    {item?.thread_gauge_requirement_1}
                  </TableCell>
                  <TableCell align="center">
                    {item?.thread_gauge_requirement_2}
                  </TableCell>
                  <TableCell align="center">
                    {item?.thread_gauge_requirement_3}
                  </TableCell>

                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>

                  <TableCell align="center">
                    <EditIconButton
                      tooltipTitle="EDIT"
                      onClick={() => {
                        setPartId(item?._id!);
                        setIsNewPartModal(true);
                      }}
                    />
                    <DeleteIconButton
                      tooltipTitle="DELETE"
                      onClick={() => {
                        if (item._id) {
                          deletePart(item._id);
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />

      <ModalCustom
        openModal={isNewPartModal}
        closeModal={() => {
          setPartId(null);
          setIsNewPartModal(false);
        }}
        title="Plating"
      >
        <AddEditPart
          setIsNewPartModal={setIsNewPartModal}
          id={partId}
          setPartId={setPartId}
        />
      </ModalCustom>
      <DialogDelete />
    </>
  );
};

export default ListModule;
