import { MenuItem, SelectChangeEvent } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';

interface IProps {
  name: string;
  value: string;
  onChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void; // Updated type
  isView: boolean;
}
const SelectCategory = ({ name, value, onChange, isView }: IProps) => {
  return (
    <div>
      {isView ? (
        value
      ) : (
        <CustomSelect name={name} value={value || ''} onChange={onChange}>
          <MenuItem value="">NONE</MenuItem>
          <MenuItem value="YES">Y</MenuItem>
          <MenuItem value="NO">N</MenuItem>
        </CustomSelect>
      )}
    </div>
  );
};

export default SelectCategory;
