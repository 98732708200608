export const tableHeaderObj = [
  [
    {
      text: 'PAST DEFECT HISTORY',
      rowspan: 1,
      colspan: 8,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Responsibility: Plant CFT ',
      rowspan: 1,
      colspan: 3,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Frequency of updating: 10th Every Month ',
      rowspan: 1,
      colspan: 7,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'APPLICABILITY & ACTION  TAKEN UPDATED AS ON 10.06.2024',
      rowspan: 1,
      colspan: 7,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
  ],
  [
    {
      text: 'Sr.NO.',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '80px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },

    {
      text: 'IAI Plant',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Date',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '120px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Category- Customer/Warranty/ Inhouse / Development (TGW/TGR) / Supplier/ Kaizen, Continual Improvement / Competitors / Sister Plant / Field failure ',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Family',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '120px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Customer',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Part Name',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Part No .',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Defect Details',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Repeated/ New',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Qty.',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '90px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Root Cause for Occurrence',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Root Cause for Non Detection',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Corrective Action of Occurrence',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Corrective Action of Non Detection',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Action Implementation Status (open/closed) ',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '120px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Lessons Learnt',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '200px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Q- Quality, D- Delivery, C- Cost, F- Feature, T- Technology, S- Sustainability, R- Risk, M- Management',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '1',
      rowspan: 1,
      colspan: 2,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '2',
      rowspan: 1,
      colspan: 2,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '3',
      rowspan: 1,
      colspan: 2,
      style: {
        minWidth: '150px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'ACTION',
      rowspan: 3,
      colspan: 1,
      style: {
        minWidth: '116px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
  ],
  [
    {
      text: 'AM5-AM6 ',
      rowspan: 1,
      colspan: 2,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: '1.0 85 KW TSI IND',
      rowspan: 1,
      colspan: 2,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'AM370 ',
      rowspan: 1,
      colspan: 2,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
  ],
  [
    {
      text: 'Applicability (Y/N)',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Action Implemented (Y / N) ',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Applicability (Y/N)',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Action Implemented (Y / N) ',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Applicability (Y/N)',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
    {
      text: 'Action Implemented (Y / N) ',
      rowspan: 1,
      colspan: 1,
      style: {
        minWidth: '100px',
        border: '1px solid black',
        background: '#38d9a9',
      },
    },
  ],
];
