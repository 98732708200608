import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import { moduleConstant } from './helpers/constant';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import moment from 'moment';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    desc: '',
    location: '',
    qty: null,
    history_card_of_incoming: '',
    issue: '',
    unit: '',
    date: null,
  });
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update' : 'Create'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`${moduleConstant.api}/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (!moduleData?.desc) {
      alert('Description is required');
      return;
    }
    if (id) {
      await axios
        .put(`${moduleConstant.api}/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => {
          if (err?.response && err?.response?.status === 409) {
            alert('Description already exist for this value');
          } else {
            console.log(err);
          }
        });
    } else {
      await axios
        .post(`${moduleConstant.api}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => {
          if (err?.response && err?.response?.status === 409) {
            alert('Description already exist for this value');
          } else {
            console.log(err);
          }
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'desc'}
        name={'desc'}
        html_for={'desc'}
        label_name={'Description:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.desc || ''}
        onChange={handleChange}
      />
      <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.location || ''}
        onChange={handleChange}
      />
      <GridInputs
        id={'qty'}
        name={'qty'}
        html_for={'qty'}
        label_name={'Qty:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.qty || ''}
        onChange={handleChange}
      />
      <GridInputs
        id={'history_card_of_incoming'}
        name={'history_card_of_incoming'}
        html_for={'history_card_of_incoming'}
        label_name={'History Card of Incoming:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.history_card_of_incoming || ''}
        onChange={handleChange}
      />
      <GridInputs
        id={'issue'}
        name={'issue'}
        html_for={'issue'}
        label_name={'Issue:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.issue || ''}
        onChange={handleChange}
      />
      <GridInputs
        id={'unit'}
        name={'unit'}
        html_for={'unit'}
        label_name={'Unit:'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.unit || ''}
        onChange={handleChange}
      />
      <DateGridMui
        lable="Date:"
        onChange={(date: any) => {
          moduleData.date = date;
          setRender((prev) => !prev);
        }}
        value={moment(moduleData.date) || null}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
