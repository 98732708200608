import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { createConstant } from '../../constant';
import TBodyRight from './TBodyRight';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
  partName: any;
  customer: any;
  vendor: any;
}

const filterBy = (
  list: any[] = [],
  partName: string = '',
  vendor: string = '',
  customer: string = '',
) => {
  return list.filter((item: any) => {
    // Check partName if provided.
    const matchesPartName = partName
      ? item.a1?.trim()?.toLowerCase() === partName.trim()?.toLowerCase()
      : true;

    // Check vendor if provided.
    const matchesVendor = vendor
      ? item.a5?.trim()?.toLowerCase() === vendor.trim()?.toLowerCase()
      : true;

    // Check customer if provided.
    const matchesCustomer = customer
      ? item.a4?.trim()?.toLowerCase() === customer.trim()?.toLowerCase()
      : true;

    // Return true only if all conditions match.
    return matchesPartName && matchesVendor && matchesCustomer;
  });
};

const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  backgroundColor: '#ffec99',
  // color: '#fff',
} as const;
const TBody = ({
  moduleData,
  isView,
  allmonth,
  partName,
  customer,
  vendor,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {filterBy(moduleData?.list, partName, vendor, customer)?.map(
        (item: any, index: number) => (
          <>
            <tr key={`${index}_table_row`}>
              <td
                rowSpan={2}
                style={{
                  ...stickStylesColumn,
                  left: 0,
                  width: '50px',
                  minWidth: '50px',
                }}
              >
                {index + 1}
              </td>
              <td
                rowSpan={2}
                style={{
                  ...stickStylesColumn,
                  left: 50,
                  width: '150px',
                  minWidth: '150px',
                }}
              >
                {item?.a1}
              </td>
              <td
                rowSpan={2}
                style={{
                  ...stickStylesColumn,
                  left: 200,
                  width: '150px',
                  minWidth: '150px',
                }}
              >
                {item?.a2}
              </td>
              <td
                rowSpan={2}
                style={{
                  ...stickStylesColumn,
                  left: 350,
                  width: '150px',
                  minWidth: '150px',
                }}
              >
                {item?.a3}
              </td>
              <td
                rowSpan={2}
                style={{
                  ...stickStylesColumn,
                  left: 500,
                  width: '150px',
                  minWidth: '150px',
                }}
              >
                {item?.a4}
              </td>

              <td
                rowSpan={2}
                style={{
                  ...stickStylesColumn,
                  left: 650,
                  width: '150px',
                  minWidth: '150px',
                }}
              >
                {item?.[`a${createConstant.extracolSpan - 1}`]}
                {item?.[`a${createConstant.extracolSpan}`] &&
                  ', ' + item?.[`a${createConstant.extracolSpan}`]}
                {item?.[`a${createConstant.extracolSpan + 1}`] &&
                  ', ' + item?.[`a${createConstant.extracolSpan + 1}`]}
                {item?.[`a${createConstant.extracolSpan + 2}`] &&
                  ', ' + item?.[`a${createConstant.extracolSpan + 2}`]}
                {item?.[`a${createConstant.extracolSpan + 3}`] &&
                  ', ' + item?.[`a${createConstant.extracolSpan + 3}`]}
              </td>

              {/* always constant ⬇️*/}
              <TBodyRight
                item={item}
                isView={isView}
                index={index}
                allmonth={allmonth}
              />
              <td rowSpan={2}>
                <TableInput
                  isView={isView}
                  style={{ height: '80px' }}
                  name="remarks"
                  type="text"
                  value={item.remarks}
                  onChange={(e) => {
                    item.remarks = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </tr>
            <tr>
              {allmonth.map((month: any, mIdx: number) => (
                <td
                  width="80px"
                  height="45px"
                  style={{
                    backgroundColor: item[month].doneDate && '#37b24d',
                    color: 'white',
                    textTransform: 'capitalize',
                    fontSize: '2.5rem',
                  }}
                >
                  {item[month].isTask && 'A'}
                </td>
              ))}
            </tr>
            {/* ---x---- */}
          </>
        ),
      )}
    </tbody>
  );
};

export default TBody;
