import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

interface MediaType {
  url: string;
  type: 'image' | 'video';
}

interface SlideProps {
  active: boolean;
}

interface ButtonProps {
  active: boolean;
}

const SlideshowContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 89.5vh;
  margin-top: 1.29rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
`;

const SlideshowWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Slide = styled.div<SlideProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  opacity: ${(props) => (props.active ? 1 : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const StyledVideo = styled.video`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Controls = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
`;

const Dot = styled.button<ButtonProps>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: ${(props) =>
    props.active ? 'white' : 'rgba(255, 255, 255, 0.5)'};
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) =>
      props.active ? 'white' : 'rgba(255, 255, 255, 0.8)'};
  }
`;

const ControlPanel = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
`;

const Button = styled.button<ButtonProps>`
  padding: 8px 16px;
  background-color: ${(props) =>
    props.active ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.7)'};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const Select = styled.select`
  padding: 8px 16px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const Slideshow: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [media, setMedia] = useState<MediaType[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isAutoPlay, setIsAutoPlay] = useState<boolean>(true);
  const [speed, setSpeed] = useState<number>(3000);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  useEffect(() => {
    if (id) {
      axios
        .get<{ files: MediaType[] }>(`/api/listOfSlideShow/${id}`)
        .then((res) => {
          // Determine media type based on file extension
          const processedMedia: any = res.data.files.map((file) => ({
            url: file.url,
            type: file.url.toLowerCase().endsWith('.mp4') ? 'video' : 'image',
          }));
          setMedia(processedMedia);
        })
        .catch((err: Error) => console.log(err.message));
    }
  }, [id]);

  useEffect(() => {
    // Pause all videos, then play the current one if it’s a video
    videoRefs.current.forEach((video, index) => {
      if (video) {
        if (index === currentIndex) {
          video.play();
        } else {
          video.pause();
          video.currentTime = 0; // Reset to start
        }
      }
    });
  }, [currentIndex]);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
  }, [media]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isAutoPlay) {
      timer = setInterval(nextSlide, speed);
    }
    return () => clearInterval(timer);
  }, [isAutoPlay, speed, nextSlide]);

  const goToSlide = (index: number): void => {
    setCurrentIndex(index);
  };

  const toggleAutoPlay = (): void => {
    setIsAutoPlay((prev) => !prev);
  };

  const handleSpeedChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setSpeed(parseInt(event.target.value) * 1000);
  };

  const renderMediaItem = (item: MediaType, index: number) => {
    if (item.type === 'video') {
      return (
        <StyledVideo
          key={index}
          src={item.url}
          ref={(el) => (videoRefs.current[index] = el)}
          autoPlay={index === currentIndex}
          muted
          loop
          playsInline
          controls={index === currentIndex} // Add controls for testing
        />
      );
    }
    return <StyledImage style={{ backgroundImage: `url(${item.url})` }} />;
  };

  return (
    <SlideshowContainer>
      <SlideshowWrapper>
        {media.map((item, index) => (
          <Slide key={index} active={index === currentIndex}>
            {renderMediaItem(item, index)}
          </Slide>
        ))}
      </SlideshowWrapper>
      <Controls>
        {media.map((_, index) => (
          <Dot
            key={index}
            active={index === currentIndex}
            onClick={() => goToSlide(index)}
          />
        ))}
      </Controls>
      <ControlPanel>
        <Button active={isAutoPlay} onClick={toggleAutoPlay}>
          {isAutoPlay ? 'Pause' : 'Play'}
        </Button>
        <Select value={speed / 1000} onChange={handleSpeedChange}>
          <option value="1">1 second</option>
          <option value="2">2 seconds</option>
          <option value="3">3 seconds</option>
          <option value="4">4 seconds</option>
          <option value="5">5 seconds</option>
          <option value="10">10 seconds</option>
          <option value="15">15 seconds</option>
          <option value="30">30 seconds</option>
          <option value="40">40 seconds</option>
          <option value="50">50 seconds</option>
          <option value="60">60 seconds</option>
        </Select>
      </ControlPanel>
    </SlideshowContainer>
  );
};

export default Slideshow;
