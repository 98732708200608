import React, { useState } from 'react';
import { Stack, TableBody } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { moduleConstant } from './helpers/constant';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../../components/ui/module.styled';
import formatDate from '../../../components/common/formatDate';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  border: '1px solid #343a40',
};
const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Description', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Location', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Qty', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Unit', rowspan: 1, colspan: 1, style: { ...style1 } },
    {
      text: 'History Card Of Incoming',
      rowspan: 1,
      colspan: 1,
      style: { ...style1 },
    },
    { text: 'Issues', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Date', rowspan: 1, colspan: 1, style: { ...style1 } },
    { text: 'Action', rowspan: 1, colspan: 1, style: { ...style1 } },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'CreatedAt' },
  { value: 'desc', name: 'Description' },
];

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [moduleId, setModuleId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `${moduleConstant.api}?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteModule = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`${moduleConstant.api}/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter]);

  return (
    <>
      <ChildHeader text={moduleConstant.title.header}>
        <AddButton
          onClick={() => setIsModal(true)}
          label={moduleConstant.title.addButton}
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <StyledTableRow>
                  <StyledTableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item?.desc}</StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.location}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item?.qty}</StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.history_card_of_incoming}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.issue}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item?.unit}</StyledTableCell>
                  <StyledTableCell align="center">
                    {item?.date && formatDate(item?.date)}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setModuleId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteModule(item?._id || '');
                        }}
                      />
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setModuleId(null);
          setIsModal(false);
        }}
        title={moduleConstant?.title?.modal}
      >
        <Create id={moduleId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
