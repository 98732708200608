import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import {
  AddButton,
  UpdateButton,
} from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../../components/common/FilterBy';
import { SearchBar } from '../../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../../components/common';
import useDebounce from '../../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import ModalCustom from '../../../../components/common/Modal';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import { competencyMatrixConstant, moduleListHeader } from './helpers/constant';
import ModalInput from './components/ModalInput';
import { useNavigate } from 'react-router-dom';
import ApprovalSelect from './components/ApprovalSelect';

const filterOptions = [{ value: 'createdAt', name: 'Most Recent First' }];

const List = () => {
  const [data, setData] = useState<any>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    `Delete ${competencyMatrixConstant.label}`,
    'Are you sure you want to delete this?',
  );
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/${competencyMatrixConstant.api}?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteModuleData = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/${competencyMatrixConstant.api}/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal]);

  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && data.length > 0) {
      axios
        .patch(`/api/pendingForApprovalRoute/updateStatus/${id}`, {
          status: data[documentIndex].status,
          model: data[documentIndex].model,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number,
  ) => {
    const values = [...data];
    values[documentIndex].status = e.target.value;
    setData(values);
  };

  return (
    <>
      <ChildHeader text={competencyMatrixConstant.label}>
        <AddButton
          onClick={() => setIsModal(true)}
          label={`New ${competencyMatrixConstant.label}`}
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={moduleListHeader}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.title}</TableCell>
                  <TableCell align="center">
                    {item?.designation_id?.designation}
                  </TableCell>
                  <TableCell align="center">
                    {item?.department_id?.department_name}
                  </TableCell>
                  <TableCell align="center">
                    <ApprovalSelect
                      {...{
                        handleChangeStatus,
                      }}
                      document={item}
                      documentIndex={index}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ViewIconButton
                        tooltipTitle="view"
                        onClick={() => {
                          navigate(
                            `${competencyMatrixConstant.createRoute}/${item._id}`,
                          );
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteModuleData(item?._id || '');
                        }}
                      />
                      <div>
                        <UpdateButton
                          label="update"
                          size="small"
                          sx={{ margin: '10px' }}
                          onClick={() => updateStatus(item._id, index)}
                        />
                      </div>
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setIsModal(false);
        }}
        title={competencyMatrixConstant.label}
      >
        <ModalInput {...{ setIsModal }} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
