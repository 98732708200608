import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
// import { useEffect, useState } from 'react';
// import axios from 'axios';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  customerData: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setModuleData: any;
  setTempData: any;
  //handleChange: any;
}

const TController = ({
  moduleData,
  customerData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  setModuleData,
  setTempData,
  //handleChange
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            setTempData
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            handelDeleteRows,
            customerData,
            // isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
            setModuleData,
            setTempData
            //handleChange
          }}
        />
        <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
