export const tabData = [
  { phase: 'phase_1', title: 'PHASE-1 (PLAN AND DEFINE PROGRAM)' },
  {
    phase: 'phase_2',
    title: 'PHASE-2 (PRODUCT DESIGN AND DEVELOPMENT)',
  },
  { phase: 'phase_3', title: 'PHASE-3 (PROCESS DESIGN AND DEVELOPMENT)' },
  {
    phase: 'phase_4',
    title: 'PHASE-4 (PRODUCT AND PROCESS VALIDATION)',
  },
  {
    phase: 'phase_5',
    title: 'PHASE -5 (FEEDBACK, ASSESSMENT AND CORRECTIVE ACTION)',
  },
];

export const npdMatrixConstant = {
  phase_1: [
    { s_no: 1, activity: 'RECEIPT OF RFQ. ALONG WITH SPECS. / DRAWINGS' },
    { s_no: 2, activity: 'CIR/STANDARD REQUIREMENT' },
    { s_no: 3, activity: 'CROSS FUNCTIONAL TEAM FORMATION' },
    { s_no: 4, activity: 'TEAM FEASIBILITY' },
    { s_no: 5, activity: 'RISK ASSESSMENT' },
    {
      s_no: 6,
      activity: 'DRAWING/PART TECHNICAL FEASIBILITY REVIEW WITH CUSTOMER',
    },
    { s_no: 7, activity: 'DRAWING RECEIVED FROM CUSTOMER AS PER FEASIBILITY' },
    { s_no: 8, activity: 'CUSTOMER NOMINATION' },
    { s_no: 9, activity: 'FEASIBILITY SIGNED OFF WITH CUSTOMER' },
    { s_no: 10, activity: 'RELEASE OF PURCHASE ODER FROM CUSTOMER' },
    { s_no: 11, activity: 'PRELIMINARY BILL OF MATERIAL' },
    { s_no: 12, activity: 'RAMP UP ACTIVITY PLAN' },
    { s_no: 13, activity: 'REVIEW (MoM)' },
  ],
  phase_2: [
    { s_no: 1, activity: 'DESIGN REVIEW / ANALYSIS' },
    {
      s_no: 2,
      activity: 'LISTING OF SPECIAL PRODUCT & PROCESS CHARACTERISTICS',
    },
    { s_no: 3, activity: 'CHILD PART DRAWING RELEASE AT IMPERIAL' },
    { s_no: 4, activity: 'FTG PLANNING' },
    { s_no: 5, activity: 'CHILD PART DRAWING REVIEW AT IMPERIAL' },
    { s_no: 6, activity: 'SUPPLIER IDENTIFICATION' },
    { s_no: 7, activity: 'RFQ TO SUPPLIER FOR CHILD PART DEVELOPMENT' },
    { s_no: 8, activity: 'FEASIBILITY REVIEW WITH SUPPLIER' },
    { s_no: 9, activity: 'PO RELEASE TO SUPPLIER' },
    { s_no: 10, activity: 'DESIGN RELEASE FOR TOOL AND GAUGES' },
    {
      s_no: 11,
      activity:
        'QUOTE BY SUPPLIER PO RELEASE BY IMPERIAL FOR TOOLING AND GAUGES',
    },
    { s_no: 12, activity: 'PROTO CHILD PART RECEIPT AND INSPECTION' },
    { s_no: 13, activity: 'INSTALLATION PLAN (IF APPLICABLE)' },
    { s_no: 14, activity: 'TOOL TRIAL PLAN / REVIEW' },
    { s_no: 15, activity: 'CHILD PART TESTING PLAN' },
    { s_no: 16, activity: 'PRELIMINARY PROCESS FLOW CHART' },
    { s_no: 17, activity: 'PROTOTYPE PROCESS FMEA' },
    { s_no: 18, activity: 'PROTOTYPE BUILD CONTROL PLAN' },
    { s_no: 19, activity: 'PACKING DESIGN & PLAN' },
    { s_no: 20, activity: 'SET QYALITY TARGETS' },
    { s_no: 21, activity: 'SUPPLIER / SUB SUPPLIER APQP STATUS SHEET' },
    { s_no: 22, activity: 'PROTOTYPE BUILDS' },
    { s_no: 23, activity: 'IMPERIAL VALIDATION / TEST.' },
    { s_no: 24, activity: 'PROTO SAMPLE SUBMISSION TO CUSTOMER' },
    { s_no: 25, activity: 'REVIEW FEEDBACK OF PROTO SAMPLES' },
    { s_no: 26, activity: 'REVIEW (MoM)' },
  ],
  phase_3: [
    { s_no: 1, activity: 'OFF TOOL SAMPLE PO TO SUPPLIER' },
    { s_no: 2, activity: 'TOOL RECEIPT AND INSPECTION' },
    { s_no: 3, activity: 'TOOL TRIAL' },
    {
      s_no: 4,
      activity: 'OFF TOOL CHILD PART / BOP / COMPONENTS MANUFACTURING',
    },
    {
      s_no: 5,
      activity:
        'FIRST OFF TOOL (FOT) CHILD PART / BOP/ COMPONENTS RECEIPT AND INSPECTION',
    },
    { s_no: 6, activity: 'OFF TOOL PROCESS FMEA' },
    { s_no: 7, activity: 'OFF TOOL CONTROL PLAN' },
    { s_no: 8, activity: 'MSA PLAN' },
    { s_no: 9, activity: 'PRELIMINARY PROCESS CAPABILITY STUDY PLAN' },
    { s_no: 10, activity: 'FLOOR LAYOUT PLAN (ASSY./ PLANT LAYOUT)' },
    { s_no: 11, activity: 'FACILITY AND EQUIPMENTS TRIAL' },
    { s_no: 12, activity: 'OFF TOOL SAMPLE MANUFACTURING' },
    { s_no: 13, activity: 'OFF TOOL SAMPLE IMPERIAL VALIDATION & REPORT' },
    { s_no: 14, activity: 'OFF TOOL SAMPLE INSPECTION & REPORT' },
    { s_no: 15, activity: 'OFF TOOL SAMPLE INTERNAL APPROVAL' },
    { s_no: 16, activity: 'FIRST OFF TOOL SAMPLE SUBMISSION TO CUSTOMER' },
    { s_no: 17, activity: 'CUSTOMER FIRST OFF TOOL SAMPLE APPROVAL' },
    { s_no: 18, activity: 'REVIEW FEEDBACK OF OFF TOOL SAMPLES' },
    { s_no: 19, activity: 'REVIEW (MoM)' },
  ],
  phase_4: [
    { s_no: 1, activity: 'PRODUCTION PROCESS FLOW CHART' },
    { s_no: 2, activity: 'PRODUCTION PROCESS FMEA' },
    { s_no: 3, activity: 'PRODUCTION CONTROL PLAN' },
    { s_no: 4, activity: 'SUPPLIER / SUB-SUPPLIER PPAP' },
    { s_no: 5, activity: 'INITIAL SAMPLE PRODUCTION TRIAL RUN' },
    { s_no: 6, activity: 'PRODUCTION VALIDATION TESTING AND REPORTS' },
    { s_no: 7, activity: 'MEASUREMENT SYSTEM ANALYSIS' },
    { s_no: 8, activity: 'SPC AND QUALITY TARGETS REVIEW' },
    { s_no: 9, activity: 'PREPARE WI or JES, PIR FORMATS, CHECK SHEETS' },
    { s_no: 10, activity: 'PACKAGING AND LOGISTICS FINALIZATION AND TRIAL' },
    { s_no: 11, activity: 'TRAINING NEED IDENTIFICATION' },
    { s_no: 12, activity: 'RECRUITMENT AND TRAINING' },
    { s_no: 13, activity: 'FINAL INSPECTION OF INITIAL SAMPLE' },
    { s_no: 14, activity: 'INITIAL SAMPLE + PPAP SUBMISSION TO CUSTOMER' },
    { s_no: 15, activity: 'INITIAL SAMPLE + PPAP APPROVAL' },
    { s_no: 16, activity: 'REVIEW (MoM)' },
  ],
  phase_5: [
    { s_no: 1, activity: 'FEED BACK ANALYSIS, CORRECTIVE ACTION' },
    { s_no: 2, activity: 'PROJECT HANDING OVER' },
    { s_no: 3, activity: 'SOP' },
    { s_no: 4, activity: 'RAMP UP ACTIVITY' },
    { s_no: 5, activity: 'REVIEW (MoM)' },
  ],
};
