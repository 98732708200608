import ExcelJS from 'exceljs';

interface SSTPlatingData {
  part_name: string;
  part_number: string;
  assly_part_number: string;
  appearance: string;
  plating_type: string;
  plating_thickness_requirement: string;
  thread_gauge_requirement_1: string;
  thread_gauge_requirement_2: string;
  thread_gauge_requirement_3: string;
}

type HeaderMappings = {
  [key: string]: keyof SSTPlatingData;
};

const normalizeHeader = (header: string): string => {
  return header
    .toLowerCase()
    .replace(/[_\s-]+/g, '')
    .replace(/[^a-z0-9]/g, '');
};

const headerMappings: HeaderMappings = {
  'desc': 'part_name',
  'description': 'part_name',
  'partname': 'part_name',
  
  'platingpartnumber': 'part_number',
  'partnumber': 'part_number',
  
  'asslypartno': 'assly_part_number',
  'assemblypartnumber': 'assly_part_number',
  'assypartnumber': 'assly_part_number',
  
  'appearance': 'appearance',
  
  'platingtype': 'plating_type',
  
  'platingthicknessrequirement': 'plating_thickness_requirement',
  'platingthickness': 'plating_thickness_requirement',
  
  'threadgaugerequirement1': 'thread_gauge_requirement_1',
  'threadgauge1': 'thread_gauge_requirement_1',
  
  'threadgaugerequirement2': 'thread_gauge_requirement_2',
  'threadgauge2': 'thread_gauge_requirement_2',
  
  'threadgaugerequirement3': 'thread_gauge_requirement_3',
  'threadgauge3': 'thread_gauge_requirement_3'
};

const getCellValueAsString = (cell: ExcelJS.Cell): string => {
  const value = cell.value;

  // Handle null/undefined
  if (value === null || value === undefined) {
    return '';
  }

  // Handle rich text objects
  if (typeof value === 'object') {
    // If it's a rich text object
    if ('richText' in value) {
      return value.richText.map((rt: any) => rt.text).join('').trim();
    }
    // If it's a rich text single object
    if ('text' in value) {
      return String(value.text).trim();
    }
    // For other objects that might have a toString method
    return value.toString().trim();
  }

  // Handle numbers, booleans, and strings directly
  return String(value).trim();
};

export const processExcelFile = async (file: File): Promise<SSTPlatingData[]> => {
  const workbook = new ExcelJS.Workbook();
  
  try {
    const arrayBuffer = await file.arrayBuffer();
    await workbook.xlsx.load(arrayBuffer);
    
    const worksheet = workbook.getWorksheet(1);
    
    if (!worksheet) {
      throw new Error('No worksheet found');
    }

    // Get headers from row 2 (index 2) instead of row 1
    const headerRow = worksheet.getRow(2);
    const headerMapping = new Map<number, keyof SSTPlatingData>();
    
    headerRow.eachCell((cell, colNumber) => {
      const headerValue = String(cell.value || '');
      const normalizedHeader = normalizeHeader(headerValue);
      const fieldName = headerMappings[normalizedHeader];
      if (fieldName) {
        headerMapping.set(colNumber, fieldName);
      }
    });

    const requiredFields: (keyof SSTPlatingData)[] = ['part_name', 'part_number'];
    const foundFields = new Set(headerMapping.values());
    const missingFields = requiredFields.filter(field => !foundFields.has(field));
    
    if (missingFields.length > 0) {
      throw new Error(`Missing required columns: ${missingFields.join(', ')}`);
    }

    const data: SSTPlatingData[] = [];
    
    // Start processing from row 3 (actual data)
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber <= 2) return; // Skip company name and header rows
      
      const rowData = {} as SSTPlatingData;
      
      headerMapping.forEach((fieldName, colNumber) => {
        const cellValue = getCellValueAsString(row.getCell(colNumber));
        // Debug log to see what's happening with problematic cells
        if (cellValue.includes('[object Object]')) {
          console.log('Problematic cell:', {
            row: rowNumber,
            column: colNumber,
            rawValue: row.getCell(colNumber).value,
            convertedValue: cellValue
          });
        }
        rowData[fieldName] = cellValue;
      });

      if (rowData.part_name && rowData.part_number) {
        data.push(rowData);
      }
    });

    return data;
  } catch (error: unknown) {
    console.error('Excel processing error:', error);
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Failed to process Excel file. Please check the file format.');
  }
};