import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../components/common/FormControlMui';
import useConfirm from '../../../components/common/useConfirm';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import { useQuery } from '../../../hooks/UseQuery';
import { Stack } from '@mui/system';

interface IProp {
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface IData {
  _id?: string;
  title?: string;
  createdAt?: Date;
}

const CreateDocument: React.FC<IProp> = ({ setIsOpenPopup, id, setId }) => {
  const [data, setData] = useState<IData>({});
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'SUBMIT DOCUMENT',
    `Are you sure you want to ${id ? 'update' : 'save'} this document?`,
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/listOfSlideShow/${id}`)
        .then((res) => {
          setData(res.data);
          if (res.data.images) {
            setPreviews(res.data.images.map((img: string) => img));
          }
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      newFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          if (event.target && event.target.result) {
            setPreviews((prev) => [...prev, event?.target?.result as string]);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const removeImage = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement> | any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;

    let formData = new FormData();
    setIsLoading(true);

    if (data.title) formData.append('title', data.title);
    files.forEach((file) => formData.append('files', file));

    const url = id ? `/api/listOfSlideShow/${id}` : '/api/listOfSlideShow';
    const method = id ? axios.put : axios.post;

    try {
      await method(url, formData);
      setIsLoading(false);
      setIsOpenPopup(false);
      setId(null);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <Box sx={{ p: 2 }}>
        <FormControlMui handleSubmit={onSubmit}>
          <Stack spacing={3}>
            {/* Input and Upload Button Row */}
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Box sx={{ flex: 1 }}>
                <InputMuiCustom
                  type="text"
                  name="title"
                  placeholder="Title"
                  onChange={handlePartData}
                  value={data?.title || ''}
                  fullWidth
                />
              </Box>
              <CustomUploadButton
                lable="UPLOAD IMAGES"
                multiple
                accept="image/png,image/jpeg,image/jpg,video/mp4"
                onChange={handleFileChange}
              />
            </Stack>

            {/* Image Grid */}
            {previews.length > 0 && (
              <Grid container spacing={2}>
                {previews.map((preview, index) => (
                  <Grid item key={index} xs={6} sm={4} md={3}>
                    <Box
                      sx={{
                        position: 'relative',
                        height: 150,
                        border: '1px solid #ccc',
                        borderRadius: 1,
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        src={preview}
                        alt={`Preview ${index}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: 5,
                          right: 5,
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        }}
                        onClick={() => removeImage(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Stack>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton label={`${id ? 'update' : 'submit'}`} type="submit" />
          </div>
        </FormControlMui>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default CreateDocument;
