// utils/errorHandler.ts

interface ServerError {
  response?: {
    status: number;
    data: {
      message?: string;
      errors?: { field: string; message: string }[]; // Array of field-based errors
    };
  };
  request?: any;
  message?: string;
}

// Function to map field names to friendly names, which will be passed dynamically from the form component
const mapFieldToFriendlyName = (
  field: string,
  fieldLabelMap: Record<string, string>
): string => {
  return fieldLabelMap[field] || field; // Return the mapped name or the original field name if no mapping exists
};

export const handleError = (
  err: ServerError,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>,
  setFieldErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>, // To set field-specific errors
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>,
  fieldLabelMap: Record<string, string> // Pass the field label map for error display
) => {
  console.log(err);
  if (err?.response) {
    const { status, data } = err.response;

    // Handle server errors based on the response status
    switch (status) {
      case 400: {
        if (data?.errors && Array.isArray(data.errors)) {
          // Handle field-based errors (validation errors)
          const fieldErrors: Record<string, string> = {};
          data.errors.forEach((error) => {
            const friendlyFieldName = mapFieldToFriendlyName(
              error.field,
              fieldLabelMap
            );
            fieldErrors[error.field] = `${friendlyFieldName}: ${error.message}`;
          });
          setFieldErrors(fieldErrors); // Set the specific field errors
        } else {
          setErrorMessage(
            data.message || 'Bad Request: Please check the input values.'
          );
        }
        break;
      }
      case 401:
        setErrorMessage('Unauthorized: You need to log in first.');
        break;
      case 403:
        setErrorMessage(
          'Forbidden: You do not have permission to perform this action.'
        );
        break;
      case 404:
        setErrorMessage(
          'Not Found: The requested resource could not be found.'
        );
        break;
      case 409:
        setErrorMessage(data.message || 'Conflict: Duplicate data entry.');
        break;
      case 500:
        setErrorMessage('Server Error: Something went wrong on the server.');
        break;
      default:
        setErrorMessage(
          'An unexpected error occurred. Please try again later.'
        );
    }
  } else if (err.request) {
    // Handle no response from the server (network issues, etc.)
    setErrorMessage(
      'No response from the server. Please check your network connection.'
    );
  } else {
    // Handle any other errors (client-side errors)
    setErrorMessage('An error occurred. Please try again.');
  }

  setOpenError(true); // Show the error message modal or toast
};
