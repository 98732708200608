import moment from 'moment';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { useQuery } from '../../../../../hooks/UseQuery';
import { StyledTableHeaderRow } from '../../../../../components/common/rowFreeze';
import { StyledTableHeaderTh1 } from '../../../../../components/common/columnFreeze';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};

const theadData = [
  'Sr. No.',
  'Date',
  'Name of Supplier',
  'Supplier Code',
  'Delivery',
  'Quality',
  'Premium Freight',
  'Customer Complaints / Customer returns / Warranty due to suppliers',
  'Line Rejection',
  'Over all Rating',
  'Grade',
  'Remark',
  'Delete',
];
const theadDataOne = [
  '100% adherance = 15',
  'Any Customer notification  / customer line stoppage due to delay or non receipt of materials',
  'OTD ( on time delievery )',
  'Marks Achieved Delivery performance out of 30',
  'No lot rejection = 20',
  'Special status customer notifications related to quality  issues',
  'Customer disruptions at the receiving plant, including yard holds and stop ships',
  'Recalls',
  'Marks Achieved Quality out of 35',
  'Nil Premium freight =05',
  'Customer complaint',
  'warranty /Dealer returns',
  'Nil Rejection = 10',
  'A ≥ 90%',
];
const theadDataTwo = [
  'As per ERP',
  '(A) Nil',
  '5',
  'On Time',
  '10',
  'As per ERP',
  'Nil',
  '5',
  'Nil',
  '5',
  'Nil',
  '5',
  'Nil',
  '5',
  'No Customer complaint',
  '10',
  'No warranty / Dealer returns',
  '10',
  'Nil',
  '10',
  '80% < B < 90%',
];
const theadDataThree = [
  '(B) Imperial Auto line stoppage due to non receipt of materials',
  '0',
  'Delay ≤4 days',
  '5',
  '',
  '',
  '',
  '',
  '',
  '',
  '≥  1 Time ',
  '03',
  '≥ 1 Nos',
  '5',
  '≥ 1 Nos',
  '5',
  '≤ 10 Nos',
  '05',
  '60% < C < 80% ',
];
const theadDataFour = [
  '%',
  'Marks',
  '(C) Any Customer notification  / customer line stoppage due to delay or non receipt of materials from supplier',
  '-30',
  'Delivery< - 5 days Delay ≥5 days',
  '0',
  '%',
  'Marks',
  'Any notification',
  '-25',
  'Any notification',
  '-25',
  'Any re-call',
  '-35',
  '≥ 2 Times ',
  '00',
  '≥ 2 Nos',
  '-10',
  '≥ 2 Nos',
  '-10',
  '≥ 15 Nos',
  '00',
  'D < 60%',
];
const THead = ({ moduleData, isView }: IProps) => {
  const date = useQuery('annual_date');
  return (
    <thead>
      <TableCompHead
        colSpan={32}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['HO-F-MTL-08', '02', '15-11-2013']}
        heading={`OVER ALL SUPPLIER RATING - (${date})`}
      />
      <StyledTableHeaderRow
        top={'-1px'}
        zIndex={2}
        style={{ background: '#38d9a9' }}
      >
        <th colSpan={32} align="left">
          For the Month of : - {date}
        </th>
      </StyledTableHeaderRow>
      <StyledTableHeaderRow
        top={'49px'}
        zIndex={3}
        style={{ background: '#38d9a9' }}
      >
        {theadData.map((heading, index) => (
          <StyledTableHeaderTh1
            key={`${index}_head`}
            align="center"
               rowSpan={index <= 3 || index === 9 || index >= 11 ? 5 : 1}
            colSpan={
              index == 4
                ? 7
                : index == 5
                ? 9
                : index == 6 || index == 8
                ? 2
                : index == 7
                ? 4
                : 1
            }
            left={
              (index == 0 && '0px') ||
              (index == 1 && '100px') ||
              (index == 2 && '300px')||
              (index == 3 && '500px')||'0px'
            }
            zIndex={index<=3?2:1}
            style={{ ...style1, background: '#da77f2'  }}
          >
            {heading}
            </StyledTableHeaderTh1>
        ))}
      </StyledTableHeaderRow>
      <StyledTableHeaderRow
        top={'99px'}
        zIndex={2}
        style={{ background: '#38d9a9' }}
      >
        {theadDataOne.map((heading, index) => (
          <StyledTableHeaderTh1
          key={heading + index}
          align="center"
          rowSpan={index == 3 || index === 8 ? 4 : 1}
             colSpan={
              index <= 2 ||
              (index >= 4 && index <= 7) ||
              (index >= 9 && index <= 12)
                ? 2
                : 1
            }
            style={
              index == 3 || index === 8 || index == 13
                ? { background: '#69db7c',...style1 }
                : index == 0 || index == 4 || index == 9
                ? { background: '#9775fa',...style1 }
                : index == 1 || index == 5 || index == 9 || index == 10
                ? { background: '#ffd43b',...style1 }
                : index == 2 || index == 6 || index == 11
                ? { background: '#ffa94d',...style1 }
                : index == 7 || index == 12
                ? { background: '#ff8787',...style1 }
                : {...style1}
            }
          zIndex={1}
          // style={{ ...style1, background: '#da77f2'  }}
        >
           {heading}
           </StyledTableHeaderTh1>
        ))}
       </StyledTableHeaderRow>
       <StyledTableHeaderRow
        top={'149px'}
        zIndex={2}
        style={{ background: '#38d9a9' }}
      >
        {theadDataTwo.map((heading, index) => (
          <th
            key={heading + index}
            rowSpan={index == 0 || index === 5 ? 2 : 1}
            colSpan={index == 0 || index == 5 ? 2 : 1}
            style={
              index == 0 || index === 5 || (index >= 12 && index <= 13)
                ? { background: '#9775fa' }
                : index <= 2 ||
                  (index >= 6 && index <= 7) ||
                  (index >= 12 && index <= 13) ||
                  (index >= 14 && index <= 15)
                ? { background: '#ffd43b' }
                : (index >= 3 && index <= 4) ||
                  (index >= 8 && index <= 9) ||
                  (index >= 16 && index <= 17)
                ? { background: '#ffa94d' }
                : (index >= 10 && index <= 11) || (index >= 18 && index <= 19)
                ? { background: '#ff8787' }
                : index == 20
                ? { background: '#69db7c' }
                : {}
            }
          >
            {heading}
          </th>
        ))}
      </StyledTableHeaderRow>
      <StyledTableHeaderRow
        top={'199px'}
        zIndex={2}
        style={{ background: '#38d9a9' }}
      >
        {theadDataThree.map((heading, index) => (
          <th
            key={heading + index}
            style={
              index <= 1 ||
              (index >= 4 && index <= 5) ||
              (index >= 12 && index <= 13)
                ? { background: '#ffd43b' }
                : (index >= 2 && index <= 3) ||
                  (index >= 6 && index <= 7) ||
                  (index >= 14 && index <= 15)
                ? { background: '#ffa94d' }
                : (index >= 8 && index <= 9) || (index >= 16 && index <= 17)
                ? { background: '#ff8787' }
                : index >= 10 && index <= 11
                ? { background: '#9775fa' }
                : index == 18
                ? { background: '#69db7c' }
                : {}
            }
          >
            {heading}
          </th>
        ))}
      </StyledTableHeaderRow>
      <StyledTableHeaderRow
        top={'249px'}
        zIndex={2}
        style={{ background: '#38d9a9' }}
      >
        {theadDataFour.map((heading, index) => (
          <th
            key={heading + index}
            style={
              index <= 1 ||
              (index >= 6 && index <= 7) ||
              (index >= 14 && index <= 15)
                ? { background: '#9775fa' }
                : (index >= 2 && index <= 3) ||
                  (index >= 8 && index <= 9) ||
                  (index >= 14 && index <= 15)
                ? { background: '#ffd43b' }
                : (index >= 4 && index <= 5) ||
                  (index >= 10 && index <= 11) ||
                  (index >= 18 && index <= 19)
                ? { background: '#ffa94d' }
                : (index >= 12 && index <= 13) || (index >= 20 && index <= 21)
                ? { background: '#ff8787' }
                : index >= 16 && index <= 17
                ? { background: '#ffd43b' }
                : index == 22
                ? { background: '#69db7c' }
                : {}
            }
          >
            {heading}
          </th>
        ))}
       </StyledTableHeaderRow>
    </thead>
  );
};

export default THead;
