import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';

import { Bar, Line } from 'react-chartjs-2';
import { GraphHead } from '../../../../../../components/common/GraphHeading';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  LinearScale
);

interface IProps {
  apiData: any;
  year: any;
  target: any;
}
const getApiData = (apiData: any) => {
  const data = [];
  let sum = 0;
  for (const val in apiData) {
    data.push(apiData[val].total_ppm_qty);
    sum += apiData[val].total_ppm_qty;
  }
  data.push(+(sum / 12).toFixed(2));
  return data;
};
const ParetoChart = ({ apiData, year, target }: IProps) => {
  const labels = [
    `APR-${year % 100}`,
    `MAY-${year % 100}`,
    `JUN-${year % 100}`,
    `JUL-${year % 100}`,
    `AUG-${year % 100}`,
    `SEP-${year % 100}`,
    `OCT-${year % 100}`,
    `NOV-${year % 100}`,
    `DEC-${year % 100}`,
    `JAN-${(year + 1) % 100}`,
    `FEB-${(year + 1) % 100}`,
    `MAR-${(year + 1) % 100}`,
    // 'AVG',
  ];
  const chartData: any = {
    labels: labels,
    datasets: [
      {
        label: 'PPM',
        data: getApiData(apiData),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: `Target PPM : ${target}`,
        data: new Array(13).fill(target).map((target: any) => target),
        type: 'line',
        borderColor: 'rgba(255, 99, 132, 0.6)',
        fill: false,
      },
    ],
  };

  const chartOptions: any = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: ``,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'PPM',
        },
        min: 0,
        ticks: {
          // forces step size to be 500 units
          stepSize: 500,
        },
      },
    },
  };

  return (
    <div>
      <GraphHead style={{ width: '80vw', margin: '14rem' }}>
        <p>{`SUPPLIER PPM (${year}-${year + 1})`}</p>
        <Bar data={chartData} options={chartOptions} />
      </GraphHead>
    </div>
  );
};

export default ParetoChart;
