export const listConstant = {
  childHeader: 'CALIBRATION plan',
  addButtonLable: 'New CALIBRATION',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'CALIBRATION plan',
  theadHeader: 'CALIBRATION Plan',
  extracolSpan: 7,
};

export const checkListList = {
  childHeader: 'CALIBRATION STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'instrumentCalibrationPlanRoute',
  gettableDataurl: 'instrumentCalibrationPlanRoute/monthly',
  getPlanByid: 'instrumentCalibrationPlanRoute',
  createPlanByid: 'instrumentCalibrationPlanRoute',
  updatePlanByid: 'instrumentCalibrationPlanRoute',
  sync: 'instrumentCalibrationPlanRoute/handleSync',
  getAllCheckList: 'instrumentCalibrationPlanRoute/getAllCheckList',
  checkListStatus: 'instrumentCalibrationPlanRoute/handleStatus',
  checklistUpload: 'instrumentCalibrationPlanRoute/handleUpload',
  checklistDeleteImage: 'instrumentCalibrationPlanRoute/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/instrumentCalibrationPlanCreate',
};
