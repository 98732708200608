import axios from 'axios';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';

const useGetData = (id: string | undefined, is_competency_matrix: boolean) => {
  const designation_id = useQuery('designation_id') || null;
  const departmentId = useQuery('departmentId') || null;
  const navigate = useNavigate();
  const [moduleData, setModuleData] = useState<any>({});
  const [error, setError] = useState(null);
  const source = axios.CancelToken.source();

  useEffect(() => {
    if (id) {
      if (is_competency_matrix) {
        axios
          .get(`/api/employeeRoute/${id}`)
          .then((res) => setModuleData(res.data))
          .catch((err) => {
            console.log(err);
          });
      } else {
        //create news
        axios
          .get(
            `/api/employeeRoute/competencyMatrixByDesignation/${id}/${designation_id}/${departmentId}`,
          )
          .then((res) => setModuleData(res.data))
          .catch((err) => {
            console.log(err);
            setError(err);
          });
      }
    }
    return () => source.cancel('Component got unmounted');
  }, []);
  return { moduleData, error, setModuleData };
};

export default useGetData;
