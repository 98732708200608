import { BsFillEaselFill, BsFillPeopleFill } from 'react-icons/bs';
import { FcDepartment } from 'react-icons/fc';
import { GrTest } from 'react-icons/gr';
import { ImUserTie } from 'react-icons/im';
import { CgUserList } from 'react-icons/cg';
import { MdOutlineSafetyDivider } from 'react-icons/md';
import { GiFirstAidKit } from 'react-icons/gi';
import { FaFireExtinguisher } from 'react-icons/fa';

const tiles = [
  {
    sNo: 1,
    link: '/common/jobDescHome',
    isLink: true,
    clauseNo: 5,
    icon: <CgUserList size="5rem" color="#135089" />,
    p: 'DEFINE JD &',
    span: 'COMPETENCY MATRIX',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/employeeList',
    isLink: true,
    clauseNo: 5,
    icon: <BsFillPeopleFill size="5rem" color="#a12222" />,
    p: 'MASTER LIST OF',
    span: 'EMPLOYEE',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterlistoffaculty?filter=createdAt',
    isLink: true,
    clauseNo: 5,
    p: 'Master List Of',
    span: 'FACULTY',
    isIcon: true,
    icon: <ImUserTie size="5rem" color="#135089" />,
  },
  {
    sNo: 5,
    link: '/common/trainingTniHome',
    isLink: true,
    p: 'TRAINING',
    clauseNo: 5,
    span: '',
    isIcon: true,
    icon: <BsFillEaselFill size="5rem" color="#135089" />,
  },
  {
    sNo: 6,
    link: `/common/firstAidBox`,
    isLink: true,
    icon: <GiFirstAidKit size="5rem" color="#003566" />,
    p: 'FIRST AID',
    span: 'BOX',
    isIcon: true,
  },
  {
    sNo: 7,
    link: `/common/listOfFireExtinguisher`,
    isLink: true,
    icon: <FaFireExtinguisher size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'FIRE EXTINGUISHER',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/skillEvaluationHome',
    isLink: true,
    p: 'Skill',
    span: 'Evaluation',
    clauseNo: 5,
    isIcon: true,
    icon: <BsFillEaselFill size="5rem" color="#135089" />,
  },
];

export default tiles;
