import React, { useState } from 'react';
import SelectCategory from './SelectCategory';
import SelectResponsibleDeparment from './SelectResponsibleDepartment';
import CustomTableInput from './CustomTableInput';
import { SelectChangeEvent } from '@mui/material';

interface IProps {
  moduleData: { [key: string]: any };
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
  isView: boolean;
  title?: string;
  phase: any;
}

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  height: '50px',
};

const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: '#fcbf49',
  color: '#11111',
} as const;

const TBody = ({ title, phase, moduleData, setModuleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>, // Updated type
    currentPhase: string,
    index: number,
  ) => {
    const { name, value } = event.target;
    setModuleData((prevData: any) => ({
      ...prevData,
      [currentPhase]: prevData[currentPhase].map(
        (item: any, i: number) =>
          i === index ? { ...item, [name as string]: value } : item, // Added as string
      ),
    }));
    setRender((prev) => !prev);
  };

  return (
    <tbody>
      <tr style={{ height: '45px' }}>
        <th colSpan={21} style={{ textTransform: 'uppercase' }}>
          {title}
        </th>
      </tr>
      {moduleData[phase].map((item: any, index: number) => (
        <React.Fragment key={`${phase}-${index}`}>
          <tr>
            <td
              style={{
                ...style1,
                ...stickStylesColumn,
                left: 0,
                minWidth: '125px',
              }}
            >
              {item.s_no}
            </td>
            <td
              colSpan={2}
              style={{
                ...style1,
                ...stickStylesColumn,
                left: 125,
                minWidth: '300px',
              }}
            >
              {item.activity}
            </td>
            {[
              'mktg',
              'dev',
              'qa',
              'prod',
              'lab',
              'purchase',
              'ppc',
              'maint',
              'store',
              'toolRm',
            ].map((name) => (
              <td
                key={`${phase}-${index}-${name}`}
                style={{ minWidth: '120px' }}
              >
                <SelectResponsibleDeparment
                  name={name}
                  value={item[name]}
                  onChange={(event) => handleChange(event, phase, index)}
                  isView={isView}
                />
              </td>
            ))}
            {['ecn', 'lc'].map((name) => (
              <td
                key={`${phase}-${index}-${name}`}
                style={{ minWidth: '120px' }}
              >
                <SelectCategory
                  name={name}
                  value={item[name]}
                  onChange={(event) => handleChange(event, phase, index)}
                  isView={isView}
                />
              </td>
            ))}
            <td style={{}}>
              <CustomTableInput
                isView={isView}
                name="status"
                type="text"
                value={item.status}
                onChange={(e) => handleChange(e, phase, index)}
                style={{ borderRadius: '5px' }}
              />
            </td>
          </tr>
        </React.Fragment>
      ))}
    </tbody>
  );
};

export default TBody;
