import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  isView: boolean;
  moduleData: any;
}
const TFoot = ({ isView, moduleData }: IProps) => {
  const [_, setRender] = useState(false);
  // console.log(moduleData.report_prepared_by);

  return (
    <tfoot>
      <tr>
        <th colSpan={9} align="left">
          PREPARED BY:
          {/* {moduleData.report_prepared_by} */}
          <TableInput
            name="report_prepared_by"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={9} align="left">
          APPROVED BY:
          {/* {moduleData.report_approved_by} */}
          <TableInput
            name="report_approved_by"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={9}>FM/XLV/RI/001</th>
        <th colSpan={9}>ISSSUE DATE: 10.01.2022</th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
