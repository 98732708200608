import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';
import { StyledTableHeaderRow } from '../../../../../components/common/rowFreeze';
import { StyledTableHeaderTh1 } from '../../../../../components/common/columnFreeze';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}
const headArr = ['Inco Qty', 'Rej Qty', 'OK Qty.', 'PPM','Quality Rating'];
const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const THead = ({ moduleData, isView }: IProps) => {
  const created_year = useQuery('created_year');
  const yearRange = getYearRange(created_year);

  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={56+13}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dt']}
        values={['', '', '']}
        heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
      />
       <StyledTableHeaderRow
        top={'-1px'}
        zIndex={2}
        style={{ background: '#38d9a9' }}
      >
        {[
          '',
          '',
          'Months --->',
          `APR - ${date}`,
          `MAY - ${date}`,
          `JUNE - ${date}`,
          `JULY - ${date}`,
          `AUG - ${date}`,
          `SEP - ${date}`,
          `OCT - ${date}`,
          `NOV - ${date}`,
          `DEC - ${date}`,
          `JAN - ${+date + 1}`,
          `FEB - ${+date + 1}`,
          `MAR - ${+date + 1}`,
          `SUPPLIER AVERAGE PPM APR'${date} ~MAR'${+date + 1}`,
          '',
        ].map((el, index) => (
           <StyledTableHeaderTh1
           key={`${index}_head`}
           align="center"
           colSpan={index >= 3 ? 5 : 1}
           left={
             (index == 0 && '0px') ||
             (index == 1 && '100px') ||
             (index == 2 && '400px')||'0px'
           }
           zIndex={index<=2 ?2:1}
           style={{ ...style1, background: '#da77f2'  }}
         >
          {el}
            </StyledTableHeaderTh1>
        ))}
      </StyledTableHeaderRow>
      <StyledTableHeaderRow
        top={'49px'}
        zIndex={2}
        style={{ background: '#38d9a9' }}
      >
        {[
          'S.No.',
          'Vendor Name',
          'Vendor Code',
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          'Delete',
        ].map((el, index) => (
           <StyledTableHeaderTh1
           key={`${index}_headcol`}
           align="center"
           left={
             (index == 0 && '0px') ||
             (index == 1 && '100px') ||
             (index == 2 && '400px')||'0px'
           }
           zIndex={index<=2 ?2:1}
           style={{ ...style1, background: '#da77f2'  }}
         >
          {el}
            </StyledTableHeaderTh1>
        ))}
      </StyledTableHeaderRow>
    </thead>
  );
};

export default THead;
