export const competencyMatrixConstant = {
  api: 'competencyMatrixRoute',
  listRoute: '/common/competencyList',
  createRoute: '/common/competencyCreate',
  label: 'Competency Matrix',
  tilesPHeading: 'Create',
  tilesSpanHeading: 'Competency Matrix',
};

export const moduleListHeader = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Title', rowspan: 1, colspan: 1 },
    { text: 'Designation', rowspan: 1, colspan: 1 },
    { text: 'Department', rowspan: 1, colspan: 1 },
    { text: 'STATUS', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

export const renderData = [null, 'title', 'designation_id'];
