import { Paper, Stack, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import formatDate from '../../../components/common/formatDate';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { apiConstant, createConstant } from './constant';
import { GoSync } from 'react-icons/go';
import useConfirm from '../../../components/common/useConfirm';
import { Loader } from '../../../components/common/Loader';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';

interface MyData {
  moduleData: any;
  Sync: any;
  isLoading: boolean;
  error?: string | null;
  handelSync: any;
}

const Create = () => {
  const { id } = useParams();
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [partData, setPartData] = useState([]);
  const [partName, setPartName] = useState<any>({});
  const [vendorData, setVendorData] = useState([]);
  const [vendor, setVendor] = useState<any>({});
  const [customerData, setCustomerData] = useState([]);
  const [customer, setCustomer] = useState<any>({});
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?',
  );
  const { moduleData, isLoading, error, handelSync, Sync }: MyData =
    useGetData(id);

  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.isSubmitted = isSubmitted;
    moduleData.report_prepared_by = state.user?.name;

    const ans = await confirmSave();
    if (!ans) return;

    axios
      .put(`/api/${apiConstant.updatePlanByid}/${id}`, moduleData)
      .then((res) => alert('updated successfully '))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(`/api/riDepartment/riPart/riPartList/autoComplete`)
      .then((res) => {
        if (res.data) {
          const uniqueData = res.data.filter(
            (item: any, index: number, self: any[]) =>
              index === self.findIndex((t) => t?.part_name === item?.part_name),
          );
          setPartData(uniqueData);
        }
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        if (res.data) {
          setVendorData(res.data);
        }
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text={`${createConstant.childHeader}`}>
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#228be6',
                  color: '#e7f5ff',
                  mr: 2,
                }}
                icon={<GoSync />}
                size="large"
                disabled={!moduleData._id}
                onClick={() => handelSync()}
              >
                Sync All RI Parts
              </CustomButton>
              <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'start',
          alignItems: 'center',
          gap: 2,
          margin: '2rem',
        }}
      >
        <AutocompleteMuiCustom
          id="part_name"
          sx={{ width: '300px' }}
          label="Filter By Part"
          option_name="part_name"
          arrayofObj={partData}
          value={partName || ''}
          onChange={(e, value) => {
            setPartName(value);
          }}
        />
        <AutocompleteMuiCustom
          id="customer_name"
          sx={{ width: '300px' }}
          label="Filter By Customer"
          option_name="customer_name"
          arrayofObj={customerData}
          value={customer || ''}
          onChange={(e, value) => {
            setCustomer(value);
          }}
        />
        <AutocompleteMuiCustom
          id="vender_name"
          sx={{ width: '300px' }}
          label="Filter By Supplier"
          option_name="vender_name"
          arrayofObj={vendorData}
          value={vendor || ''}
          onChange={(e, value) => {
            setVendor(value);
          }}
        />
      </Stack>
      <TController
        {...{ moduleData, isView }}
        customer={customer?.customer_name}
        vendor={vendor?.vender_name}
        partName={partName?.part_name}
      />
      <Sync isSubmitContent={false} />
      <DialogSave isSubmitContent={false} />
    </Box>
  );
};

export default Create;
