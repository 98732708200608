import { Box } from '@mui/material';
import { useState } from 'react';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import CreateDrawing from './components/CreateDrawing';
import SupplierDocumentList from './components/SupplierDocumentList';
import ViewFile from './components/ViewFile';

const headings: { [key: string]: string } = {
  supplierSpcPlantList: 'SUPPLIER SPC PLANT',
  supplierMtcPlanList: 'SUPPLIER MTC PLAN',
  supplierPpapDocument: 'SUPPLIER PPAP DOCUMENT',
  supplierSSTPlan: 'SUPPLIER SST PLAN',
};
const SupplierDocument = () => {
  const { state } = useAuth();
  const documentType = useQuery('documentType') || '';
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);

  return (
    <Box>
      <ChildHeader text={documentType && headings[documentType]}>
        {state.user?.userType === 2 && (
          <AddButton
            label="UPLOAD NEW DOCUMENT"
            onClick={() => setIsOpenPopup(true)}
          />
        )}
      </ChildHeader>
      <SupplierDocumentList
        {...{ isOpenPopup, setIsFileOpenPopup, setIsOpenPopup, setId }}
      />
      <ModalCustom
        title="CREATE UPLOAD NEW DOCUMENT"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
          setId(null);
        }}
      >
        <CreateDrawing {...{ id, setId, setIsOpenPopup }} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} />
      </ModalCustom>
    </Box>
  );
};

export default SupplierDocument;
