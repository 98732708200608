import { TbReport } from 'react-icons/tb';
import { FiCrosshair } from 'react-icons/fi';
import { MdAnalytics } from 'react-icons/md';
import ChildHeader from '../../../components/ui/ChildHeader';
import { RenderTiles } from '../../../components/common';

const tiles = [
  {
    sNo: 1,
    link: '/common/sst/plating',
    isLink: true,
    icon: <FiCrosshair size="5rem" color="#003566" />,
    p: 'PLATING',
    span: 'LIST',
    isIcon: true,
  },
  {
    sNo: 4,
    link: `/common/sst/plating/inspection/standard/list`,
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'PLATING INSPECTION',
    span: 'STANDARD',
    isIcon: true,
  },
  {
    sNo: 4,
    link: `/common/sst/plating/inspection/report/list`,
    isLink: true,
    icon: <MdAnalytics size="5rem" color="#a12222" />,
    p: 'PLATING INSPECTION',
    span: 'REPORT',
    isIcon: true,
  },
];

const Home = () => {
  return (
    <>
      <ChildHeader text="PLATING" />
      <RenderTiles tilesObj={tiles} height="100%" />
    </>
  );
};

export default Home;
