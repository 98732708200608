import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';

import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';
import { Checkbox } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
  });
  const [files, setFiles] = useState<any>(null);
  const [images, setImages] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Vendor' : 'Create Ventor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  const [customerData, setCustomerData] = useState([]);
  const [defectData, setDefectData] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/inHouseComplaintRegisterRoutes/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        setDefectData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.customer_id) {
      alert('Please enter customer name');
      return;
    }
    if (!data.type_of_complaint) {
      alert('Please enter type of complaint(Domestic/Exports)');
      return;
    }
    if (!data.defect_id) {
      alert('Please enter defect name');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);
    const formData = new FormData();

    if (typeof data.customer_id === 'object') {
      data.customer_id = data.customer_id._id;
    }
    if (typeof data.defect_id === 'object') {
      data.defect_id = data.defect_id._id;
    }
    if (typeof data.part_id === 'object') {
      data.part_id = data.part_id._id;
    }
    for (const key in data) {
      if (data[key] || typeof data[key] == 'boolean') {
        if (key !== 'files' && key !== 'images') {
          formData.append(key, data[key]);
        }
      }
    }

    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (images) {
      for (const key of Object.keys(images)) {
        formData.append('images', images[key]);
      }
    }

    if (id) {
      await axios
        .put(`/api/inHouseComplaintRegisterRoutes/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/inHouseComplaintRegisterRoutes', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridDatePicker
        label_name="Issue Reported Date"
        html_for={'issue_reported_date'}
        value={data?.issue_reported_date || null}
        onChange={(date) => {
          data.issue_reported_date = date;
        }}
      />
      <GridSelect
        name="type_of_complaint"
        label_name="Type of Complaint"
        html_for={'type_of_complaint'}
        value={data?.type_of_complaint || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Domestic">Domestic</MenuItem>
        <MenuItem value="Export">Export</MenuItem>
        <MenuItem value="INHOUSE">Inhouse</MenuItem>
      </GridSelect>
      <Grid item xs={3} style={{ marginTop: '10px' }}>
        <AutocompleteMuiCustom
          id={'customer_name'}
          label={'Customer Name'}
          option_name={'customer_name'}
          arrayofObj={customerData}
          value={data.customer_name || data.customer_id || ''}
          onChange={(e, value) => {
            data.customer_id = value._id;
            data.customer_name = value;
            setRender((prev) => !prev);
          }}
        />
      </Grid>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Number'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_number
                  : data?.partNumber || ''}
              </p>
            </Grid>

            {data.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.part_id = null;
                    data.partNumber = null;
                    data.partName = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <Grid item xs={3} style={{ marginTop: '10px' }}>
        <AutocompleteMuiCustom
          id={'defect_name'}
          label={'Reason of Rejection/ Defect Reported'}
          option_name={'defect_name'}
          arrayofObj={defectData}
          value={data.defect_name || data.defect_id || ''}
          onChange={(e, value) => {
            data.defect_id = value._id;
            data.defect_name = value;
            setRender((prev) => !prev);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Defect photo'}
          multiple
          id="defect_photo"
          accept="image/png,image/jpeg,image/jpg"
          onChange={(e) => {
            if (files) {
              setImages([...files, ...e.target.files]);
            } else {
              setImages(e.target.files);
            }
          }}
        />{' '}
      </Grid>
      <GridSelect
        name="defect_category"
        label_name="Defect Category"
        html_for={'defect_category'}
        value={data?.defect_category || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Fit">Fit</MenuItem>
        <MenuItem value="Function">Function</MenuItem>
        <MenuItem value="Form">Form</MenuItem>
      </GridSelect>
      <GridInputs
        id={'customer_end_qty'}
        name={'customer_end_qty'}
        html_for={'customer_end_qty'}
        label_name={'Customer End Qty.'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.customer_end_qty || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'bar_code_date'}
        name={'bar_code_date'}
        html_for={'bar_code_date'}
        label_name={'Batch Code/ Bar Code Date:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.bar_code_date || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'defective_part_status'}
        name={'defective_part_status'}
        html_for={'defective_part_status'}
        label_name={'Defective Part Status:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.defective_part_status || ''}
        onChange={handleCustomerData}
      />
      <GridSelect
        name="firstTime_repeated_ndp"
        label_name="1st Time/Repeated/NDP"
        html_for={'firstTime_repeated_ndp'}
        value={data?.firstTime_repeated_ndp || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="First Time">First Time</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
        <MenuItem value="NDP">NDP</MenuItem>
      </GridSelect>
      <GridSelect
        name="defect_category_4m_type"
        label_name="Defect Category(4M Type)"
        html_for={'defect_category_4m_type'}
        value={data?.defect_category_4m_type || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Man">Man</MenuItem>
        <MenuItem value="Machine">Machine</MenuItem>
        <MenuItem value="Method">Method</MenuItem>
        <MenuItem value="Material">Material</MenuItem>
      </GridSelect>
      <GridInputs
        id={'short_term_action'}
        name={'short_term_action'}
        html_for={'short_term_action'}
        label_name={'Short Term Action(Containment):'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.short_term_action || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'root_cause'}
        name={'root_cause'}
        html_for={'root_cause'}
        label_name={'Root Cause(Occurrence and Detection):'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.root_cause || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'action_taken'}
        name={'action_taken'}
        html_for={'action_taken'}
        label_name={'Action Taken:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.action_taken || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="Target Date"
        html_for={'target_date'}
        value={data?.target_date || null}
        onChange={(date) => {
          data.target_date = date;
        }}
      />
      <GridDatePicker
        label_name="Actual Closure Date"
        html_for={'actual_closure_date'}
        value={data?.actual_closure_date || null}
        onChange={(date) => {
          data.actual_closure_date = date;
        }}
      />
      <GridSelect
        name="status"
        label_name="Status"
        html_for={'status'}
        value={data?.status || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="CLOSED">CLOSED</MenuItem>
        <MenuItem value="OPEN">OPEN</MenuItem>
        <MenuItem value="REVERSED">REVERSED</MenuItem>
        <MenuItem value="UNDER REVERSAL">UNDER REVERSAL</MenuItem>
        <MenuItem value="ACTION SUBMITTED">ACTION SUBMITTED</MenuItem>
      </GridSelect>

      <GridInputs
        id={'in_house_rejection'}
        name={'in_house_rejection'}
        html_for={'in_house_rejection'}
        label_name={'In House Rejection:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.in_house_rejection || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'effective_qty'}
        name={'effective_qty'}
        html_for={'effective_qty'}
        label_name={'Effective Qty:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.effective_qty || ''}
        onChange={handleCustomerData}
      />

      <h1>List of documents updated</h1>
      <GridSelect
        name="control_plan"
        label_name="Control Plan"
        html_for={'control_plan'}
        value={data?.control_plan || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="pfmea"
        label_name="PFMEA"
        html_for={'pfmea'}
        value={data?.pfmea || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="quality_alert"
        label_name="Quality Alert"
        html_for={'quality_alert'}
        value={data?.quality_alert || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <GridSelect
        name="wi"
        label_name="WI"
        html_for={'wi'}
        value={data?.wi || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </GridSelect>
      <h1>Effectiveness Monitoring</h1>
      <GridSelect
        name="jan"
        label_name="Jan"
        html_for={'jan'}
        value={data?.jan || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="feb"
        label_name="Feb"
        html_for={'feb'}
        value={data?.feb || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="mar"
        label_name="Mar"
        html_for={'mar'}
        value={data?.mar || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="apr"
        label_name="Apr"
        html_for={'apr'}
        value={data?.apr || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="may"
        label_name="May"
        html_for={'may'}
        value={data?.may || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="jun"
        label_name="Jun"
        html_for={'jun'}
        value={data?.jun || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="jul"
        label_name="Jul"
        html_for={'jul'}
        value={data?.jul || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="aug"
        label_name="Aug"
        html_for={'aug'}
        value={data?.aug || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="sep"
        label_name="Sep"
        html_for={'sep'}
        value={data?.sep || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="oct"
        label_name="Oct"
        html_for={'oct'}
        value={data?.oct || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="nov"
        label_name="Nov"
        html_for={'nov'}
        value={data?.nov || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <GridSelect
        name="dec"
        label_name="Dec"
        html_for={'dec'}
        value={data?.dec || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Not Repeated">Not Repeated</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
      </GridSelect>
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Attach Supporting Doucments'}
          id="attach_supporting_document"
          multiple
          accept="application/pdf,application/doc,application/excel/,application/ppt"
          onChange={(e) => {
            if (files) {
              setFiles([...files, ...e.target.files]);
            } else {
              setFiles(e.target.files);
            }
          }}
        />{' '}
      </Grid>
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
