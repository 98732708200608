import axios from 'axios';
import lodash from 'lodash';
import { useState, useEffect } from 'react';
import { useQuery } from '../../../hooks/UseQuery';

const useGetData = (
  partId: string | undefined | null,
  pdirId: string | undefined | null
) => {
  const [moduleData, setModuleData] = useState<any>({
    inspection_date: new Date(),
    processes: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const vendorId = useQuery('vendorId');
  const qty = useQuery('qty');
  const mrn_no = useQuery('mrn_no');
  useEffect(() => {
    setIsLoading(true);
    if (pdirId !== 'undefined' && pdirId) {
      axios
        .get(`/api/inspectionReport/${pdirId}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      axios
        .get(`/api/inspectionReport/getSpecFromInspectionStd/${partId}`)
        .then((res) => {
          const response = res.data;
          setModuleData((item: any) => {
            item.ri_part_id = partId;
            item.inspection_date = new Date();
            item.part_number = partNumber;
            item.part_name = partName;
            item.vendor_id = vendorId;
            item.qty = Number(qty);
            item.mrn_no = mrn_no;
            item.processes = response.map((process: any) => {
              const processObj = {
                ref_drg_no: process.ref_drg_no,
                drawing_no: process.drawing_no,
                file: process.file,
                specs: [],
              };
              processObj.specs = process.specs.map((spec: any) => {
                return {
                  // start of spec in inspection
                  ballooning_drawing_no: spec.ballooning_drawing_no,
                  parameter: spec.parameter,
                  specification: spec?.specification,
                  instrument_type: spec?.instrument_type,
                  freq: spec?.freq,
                  machine: spec.machine,
                  tool: spec.tool,
                  special_character: spec.special_character,
                  check4_symbol: spec.check4_symbol,
                  checkbox4_num: spec.checkbox4_num,
                  methods: spec.methods_measure,
                  appearance: spec.appearance,
                  mean: spec.mean,
                  utolerance: spec.utolerance,
                  ltolerance: spec.ltolerance,
                  utl: spec.utl,
                  ltl: spec.ltl,
                  check_0_colors: Array.from(Array(10)),
                  check_0_values: Array.from(Array(10)),
                };
              });
              return lodash.cloneDeep(processObj);
            });
            return { ...item };
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  }, []);
  return { moduleData, isLoading, error };
};

export default useGetData;
