import { MenuItem, SelectChangeEvent } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';

interface IProps {
  name: string;
  value: string;
  onChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
  isView: boolean;
}

const SelectResponsibleDeparment = ({
  name,
  value,
  onChange,
  isView,
}: IProps) => {
  return (
    <>
      {isView ? (
        value
      ) : (
        <CustomSelect name={name} value={value || ''} onChange={onChange}>
          <MenuItem value="">NONE</MenuItem>
          <MenuItem value="NA">N/A</MenuItem>
          <MenuItem value="MAJOR">⊚︎</MenuItem>
          <MenuItem value="MINOR">⏺︎</MenuItem>
        </CustomSelect>
      )}
    </>
  );
};

export default SelectResponsibleDeparment;
