import {
  HiAdjustments,
  HiCamera,
  HiClipboardCheck,
  HiClipboardCopy,
  HiCog,
  HiDocumentReport,
  HiOutlineDatabase,
  HiTerminal,
} from 'react-icons/hi';
import { SiConsul } from 'react-icons/si';

import { MdCreate } from 'react-icons/md';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
const tiles = [
  {
    sNo: 1,
    link: '/common/storePartCreate',
    isLink: true,
    icon: <HiCog size="5rem" color="#003566" />,
    p: 'STORE',
    span: 'PART',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/storeBinCreationList',
    isLink: true,
    icon: <MdCreate size="5rem" color="#003566" />,
    p: 'STORE',
    span: 'CREATION',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/storeSetupCreate',
    isLink: true,
    icon: <HiOutlineDatabase size="5rem" color="#003566" />,
    p: 'STORE',
    span: 'REGISTER',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/storeReceiveRegisterList',
    isLink: true,
    icon: <HiClipboardCheck size="5rem" color="#003566" />,
    p: 'RECEIVE',
    span: 'REGISTER',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/storeAssignRegisterList',
    isLink: true,
    icon: <HiClipboardCopy size="5rem" color="#003566" />,
    p: 'ASSIGN',
    span: 'REGISTER',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/issueRegisterList',
    isLink: true,
    icon: <HiTerminal size="5rem" color="#003566" />,
    p: 'ISSUE',
    span: 'REGISTER',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/storeOverviewCreate',
    isLink: true,
    icon: <HiCamera size="5rem" color="#003566" />,
    p: 'STORE',
    span: 'OVERVIEW',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/store/consumable/list',
    isLink: true,
    icon: <SiConsul size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'CONSUMABLE',
    isIcon: true,
  },
];

const StoreManagementHome = () => {
  return (
    <>
      <ChildHeader text="Store Management" />
      <RenderTiles justify="start" height="100vh" tilesObj={tiles} />
    </>
  );
};

export default StoreManagementHome;
