import React, { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import useDebounce from '../../../../../hooks/useDebounce';
import StateCustomTable from '../../../../../components/common/StateCustomTable';
import { StatePagination, TableCustom } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import { CustomIconButton } from '../../../../../components/common/button/CustomIconButton';

const tableHeaderObj = [
  [
    { text: 'Sr. No.', rowspan: 1, colspan: 1 },
    { text: 'Desc.', rowspan: 1, colspan: 1 },
    { text: 'Plating Part Number', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'part_name', name: 'A-Z' }];

const PickSST = ({ link, type }: { link?: string; type?: string }) => {
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<any[]>([]);
  const [perPage, setPerPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('part_name');
  const navigate = useNavigate();

  const fetchPartData = () => {
    axios
      .get(
        `/api/sstPlatingRoute?page=${page}&sortBy=${filter}&search=${search}&perPage=${10}`
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setPartData(res.data?.result);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);

  return (
    <StateCustomTable
      filterOptions={filterOptions}
      {...{ search, setSearch, filter, setFilter }}
      label="Search Part"
    >
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {partData &&
            partData.map((item: any, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item?.part_name}</TableCell>
                  <TableCell align="center">{item?.part_number}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell align="center">
                    <CustomIconButton
                      sx={{ p: 0 }}
                      tooltipTitle={'select'}
                      onClick={() => {
                        navigate(
                          `${link}/${item?._id}?partName=${item?.part_name}&partNumber=${item?.part_number}&isView=false`
                        );
                      }}
                    >
                      <Add style={{ color: '#343a40' }} />
                    </CustomIconButton>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
    </StateCustomTable>
  );
};

export default PickSST;
