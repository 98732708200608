import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { moduleConstant } from './helpers/constant';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';

interface MyData {
  moduleData: any; // replace PdirType with the type of your `${moduleConstant.api}` data
  // isLoading: boolean;
  setModuleData: any;
  error?: string | null;
}
const Create = () => {
  const { moduleId } = useParams();
  const { state } = useAuth();
  const isView = useQuery('isView') === 'true' ? true : false;
  const navigate = useNavigate();
  const is_job_description =
    useQuery('is_job_description') === 'true' ? true : false;
  const { error, moduleData, setModuleData }: MyData = useGetData(
    moduleId,
    is_job_description,
  );

  const onSubmit = async () => {
    let response: any;
    if (moduleData._id) {
      moduleData.is_job_description = true;
      response = await axios
        .put(`/api/${moduleConstant.api}/${moduleData._id}`, moduleData)
        .then((res) => {
          alert('updated successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
    } else {
      alert('Job Description for this Designation does not exist');
      navigate(-1);
    }
  };

  const handelDeleteRows = async (process_idx: string, rowIdx: number) => {
    // if (!ans) return;
    let data = { ...moduleData };
    data.job_description[process_idx].splice(rowIdx, 1);
    setModuleData(data);
  };

  const deleteJD = async () => {
    const ans = window.confirm(
      'Are you sure you want to permanently delete this JOB Description',
    );
    if (ans) {
      moduleData.job_description = {};
      if (moduleData._id) {
        moduleData.is_job_description = false;
        await axios
          .put(`/api/${moduleConstant.api}/${moduleData._id}`, moduleData)
          .then((res) => {
            alert('delete successfully');
            navigate(-1);
          })
          .catch((err) => console.log(err));
      }
    }
  };
  if (error) {
    return (
      <Typography textAlign={'center'} mt={5} variant={'h5'}>
        JD IS STILL NOT APPROVED, PLEASE CONTACT YOUR HOD
      </Typography>
    );
  }

  return (
    <Box>
      <Box>
        <ChildHeader text="JOB CARD">
          {!isView && (
            <>
              <DeleteIconButton
                tooltipTitle="DELETE JOB CARD"
                onClick={() => {
                  deleteJD();
                }}
              />
              <SubmitButton
                label={is_job_description ? 'Update' : 'Submit'}
                onClick={() => onSubmit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>

      <TController {...{ moduleData, isView, handelDeleteRows }} />
    </Box>
  );
};

export default Create;
