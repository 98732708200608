import ExcelJS from 'exceljs';
import moment from 'moment';
import axios from 'axios';


const headerNames = [
  'SR. NO.', 'DATE', 'SHIFT', 'PART NO.', 'CUSTOMER NAME', 'JOB CARD NUMBER',
  'ITEM CODE', 'RESPONSIBILITY', '4M CHANGE', 'PROCESS', 'PROCESS ENGINEER',
  'PROD. QTY.', 'REJECTION QTY.', 'PROBLEM', 'CAUSE', 'ACTION', 'REMARKS'
];

const cellDataAccessors = [
  (process:any, index:any) => index + 1,
  (process: any) => process.date ? moment(process.date).format('DD/MM/YYYY') : '',
  (process: any) => process.shift,
  (process: any) => process.part_id?.part_number || '',
  (process: any) => process.customer_id?.customer_name || '',
  (process: any) => process.job_card_number,
  (process: any) => process.item_code,
  (process: any) => process.responsibility,
  (process: any) => process.fourMChange,
  (process: any) => process.operation,
  (process: any) => process.process_engineer,
  (process: any) => process.prod_qty,
  (process: any) => process.rej_qty,
  (process: any) => process.problem,
  (process: any) => process.cause,
  (process: any) => process.action_taken,
  (process: any) => process.remarks
];

async function fetchImageBuffer(url:any) {
    try {
      const response = await axios.get(url, { responseType: 'arraybuffer' });
      return response.data;
    } catch (error) {
      console.error('Error fetching image:', error);
      return null;
    }
  }

  export async function generateExcelFile(moduleData:any) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
  
    // Fetch and set the image
    const imageUrl = 'https://proxy.aqua.mushinlabs.com/proxy/https://mushin-labs.s3.ap-south-1.amazonaws.com/imperial-auto/logos/Logo.png';
    const imageBuffer = await fetchImageBuffer(imageUrl);
    
    if (imageBuffer) {
      const logoId = workbook.addImage({
        buffer: imageBuffer,
        extension: 'png',
      });
      worksheet.addImage(logoId, {
        tl: { col: 0, row: 0 },
        ext: { width: 180, height: 60 }
      });
    } else {
      console.warn('Failed to add image to Excel file');
    }
  
    // Set the height of the first row to 60
    worksheet.getRow(1).height = 60;
  
    // Add and style the first header row (with image)
    worksheet.mergeCells('A1:Q1');
    const firstHeader = worksheet.getCell('A1');
    firstHeader.value = 'IMPERIAL AUTO INDUSTRIES LIMITED';
    firstHeader.font = { name: 'Calibri', size: 20, bold: true, color: { argb: 'FF000000' } };
    firstHeader.alignment = { horizontal: 'center', vertical: 'middle' };
  
    // Add and style the second header row
    worksheet.mergeCells('A2:Q2');
    const secondHeader = worksheet.getCell('A2');
    secondHeader.value = 'DAILY INPROCESS REJECTION MONITORING SHEET';
    secondHeader.font = { name: 'Calibri', size: 20, bold: true, color: { argb: 'FFFFFFFF' } };
    secondHeader.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF212529' } };
    secondHeader.alignment = { horizontal: 'center', vertical: 'middle' };
  
    // Add date and shift rows (now shifted down by one row)
    worksheet.mergeCells('A3:B3');
    worksheet.getCell('A3').value = 'Date :';
    worksheet.mergeCells('C3:I3');
    worksheet.getCell('C3').value = moment(moduleData.dailyRejMon_date).format('DD/MM/YYYY');
    worksheet.mergeCells('J3:K3');
    worksheet.getCell('J3').value = 'Shift :';
    worksheet.mergeCells('L3:R3');
    worksheet.getCell('L3').value = moduleData.shift;
  
    // Style the date and shift rows
    ['A3', 'C3', 'J3', 'L3'].forEach(cellRef => {
      const cell = worksheet.getCell(cellRef);
      cell.font = { name: 'Calibri', size: 11, bold: true };
      cell.alignment = { horizontal: 'left', vertical: 'middle' };
    });
  
    // Add and style the column headers (now on row 4)
    headerNames.forEach((header, index) => {
      const cell = worksheet.getCell(4, index + 1);
      cell.value = header;
      cell.font = { name: 'Calibri', size: 13, bold: true, color: { argb: 'FFFFFFFF' } };
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF4F81BD' } };
      cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  
    // Add data rows (now starting from row 5)
    moduleData.processes.forEach((process:any, rowIndex:any) => {
      cellDataAccessors.forEach((accessor, colIndex) => {
        const cell = worksheet.getCell(rowIndex + 5, colIndex + 1);
        cell.value = accessor(process, rowIndex);
        cell.font = { name: 'Calibri', size: 11 };
        cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });
  
    // Set column widths
    const columnWidths = [10, 15, 10, 20, 20, 20, 15, 20, 15, 20, 20, 15, 15, 20, 20, 20, 20];
    worksheet.columns.forEach((column, index) => {
      column.width = columnWidths[index];
    });
  
    // Add footers (now shifted down by one row)
    const lastRow = worksheet.rowCount + 2;
    worksheet.mergeCells(`A${lastRow}:I${lastRow}`);
    worksheet.getCell(`A${lastRow}`).value = `Prepared By: ${moduleData.report_prepared_by || ''}`;
    worksheet.mergeCells(`J${lastRow}:R${lastRow}`);
    worksheet.getCell(`J${lastRow}`).value = `Approved By: ${moduleData.report_approved_by || ''}`;
  
    // Style footers
    [`A${lastRow}`, `J${lastRow}`].forEach(cellRef => {
      const cell = worksheet.getCell(cellRef);
      cell.font = { name: 'Calibri', size: 11, bold: true };
      cell.alignment = { horizontal: 'left', vertical: 'middle' };
    });
  
    // Generate and return the Excel file
    return await workbook.xlsx.writeBuffer();
  }