import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={18}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="INSPECTION REPORT"
      />
      <tr>
        <th colSpan={18} align="left">
          Date :{' '}
          {isView ? (
            formatDate(moduleData.inspection_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.inspection_date)}
              onChange={(date) => {
                moduleData.inspection_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          MRN NO:-
          <TableInput
            isView={isView}
            name="mrn_no"
            type="text"
            value={moduleData?.mrn_no}
            onChange={(e) => {
              moduleData.mrn_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <td colSpan={13}></td>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Vendor Name:-
          <TableInput
            isView={isView}
            name="vendor_name"
            type="text"
            value={moduleData?.vendor_name}
            onChange={(e) => {
              moduleData.vendor_name = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Qty.:{' '}
          <TableInput
            isView={isView}
            name="qty"
            type="number"
            value={moduleData?.qty}
            onChange={(e) => {
              moduleData.qty = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={8} align="left">
          PART NO:{' '}
          {moduleData?.part_number
            ? moduleData?.part_number
            : moduleData?.plating_id?.part_number}{' '}
        </th>
        <th colSpan={5} align="left">
          PART NO:{' '}
          {moduleData?.part_name
            ? moduleData?.part_name
            : moduleData?.plating_id?.part_name}{' '}
        </th>
      </tr>

      <tr>
        {[
          'S.No',
          'Parameter',
          'Specification',
          'Inst.Type',
          'Sample Qty',
          'Observation',
        ].map((head, index) => (
          <th colSpan={index === 5 ? 13 : 1} rowSpan={index === 5 ? 1 : 2}>
            {head}
          </th>
        ))}
      </tr>
      <tr>
        {Array.from({ length: 10 }, (_, index) => (
          <th style={{ width: '110px' }} key={index + 'Heading'}>
            {index + 1}
          </th>
        ))}
        <th>Status</th>
        <th colSpan={2}>Remark</th>
      </tr>
    </thead>
  );
};

export default THead;
