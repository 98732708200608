import { useState } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../../components/common/SelectField';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  customerData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setTempData: any
}

const TBody = ({
  moduleData,
  customerData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  setTempData
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 10.1 + 'heading '}>
              <th style={{ minWidth: '50px', textAlign: 'center' }}>
                {index + 1}
              </th>
              <td style={{ minWidth: '180px', textAlign: 'center' }}>
                <DatePickerMui
                  label=""
                  value={process.date ? moment(process.date) : null}
                  onChange={(date) => {
                    process.date = date;
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                {isView ? (
                  process?.shift
                ) : (
                  <AutocompleteMuiCustom
                    id={`shift_${index}`}
                    option_name="shift"
                    arrayofObj={[{shift: "A" }, { shift: "B" }]}
                    value={
                      {shift: process.shift} || ''
                    }
                    onChange={(e, value) => {
                      process.shift = value?.shift || '';
                      setTempData({...moduleData});
                      setRender((prev) => !prev);
                    }
                    }
                  />
                )}
              </td>

              <td
                style={{
                  width: '280px',
                  minWidth: '280px',
                  textAlign: 'center',
                }}
              >
                {isView ? (
                  process?.part_id && process?.part_id?.part_number
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.2rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                          process?.part_id !== null
                          ? process?.part_id?.part_number
                          : process?.partNumber || ''}
                        {!process?.part_id && 'Pick Part Number'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            process.operations = [];
                            setTempData({...moduleData});
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                            setTempData({...moduleData});
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '200px', textAlign: 'center' }}>
                {isView ? (
                  process.customer_id?.customer_name
                ) : (
                  <AutocompleteMuiCustom
                    id="customer_name"
                    option_name="customer_name"
                    arrayofObj={customerData}
                    value={
                      process.customer_autocomplete || process.customer_id || ''
                    }
                    onChange={(e, value) => {
                      process.customer_id = value?._id;
                      process.customer_autocomplete = value;
                      setTempData({...moduleData});
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '200px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="job_card_number"
                  type="text"
                  value={process.job_card_number}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="item_code"
                  type="text"
                  value={process.item_code}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>






              <td style={{ minWidth: '250px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="responsibility"
                  type="text"
                  value={process.responsibility}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '250px', textAlign: 'center' }}>
                {isView ? (
                  process?.fourMChange
                ) : (
                  <AutocompleteMuiCustom
                    id={`fourMChange_${index}`}
                    option_name="fourMChange"
                    arrayofObj={[{fourMChange: "MAN" }, { fourMChange: "MACHINE" }, { fourMChange: "METHOD" }, { fourMChange: "MATERIAL" }]}
                    value={
                      {fourMChange: process.fourMChange} || ''
                    }
                    onChange={(e, value) => {
                      process.fourMChange = value?.fourMChange || '';
                      setTempData({...moduleData});
                      setRender((prev) => !prev);
                    }
                    }
                  />
                )}
              </td>






              <td style={{ minWidth: '300px', textAlign: 'center' }}>
                {isView ? (
                  process.operation
                ) : (
                  <CustomSelect
                    name="operation"
                    value={process.operation}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setTempData({...moduleData});
                      setRender((prev) => !prev);
                    }}
                  >
                    {process.operations?.map((operation: any, ind: number) => (
                      <MenuItem value={operation.process_name}>
                        {operation.process_name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="process_engineer"
                  type="text"
                  value={process.process_engineer}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="prod_qty"
                  type="number"
                  value={process.prod_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="rej_qty"
                  type="number"
                  value={process.rej_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="problem"
                  type="text"
                  value={process.problem}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="cause"
                  type="text"
                  value={process.cause}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="action_taken"
                  type="text"
                  value={process.action_taken}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td
                style={{ minWidth: '100px', textAlign: 'center' }}
                colSpan={isView ? 2 : 1}
              >
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setTempData({...moduleData});
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
