import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { SubmitButton } from '../../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../../components/common/FormControlMui';
import useConfirm from '../../../../../components/common/useConfirm';

interface Iprop {
  setIsNewPartModal: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setPartId: React.Dispatch<React.SetStateAction<string | null>>;
}

const EditModule = ({ setIsNewPartModal, id, setPartId }: Iprop) => {
  const [partData, setPartData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Plating' : 'Create Plating'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this part?`
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPartData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/sstPlatingRoute/${id}`)
        .then((res) => {
          setPartData(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);

    try {
      // If id exists, update; otherwise, create a new part
      const route = id ? `/api/sstPlatingRoute/${id}` : '/api/sstPlatingRoute';
      const method = id ? 'put' : 'post';

      // Send the request with the appropriate method
      await axios({
        method: method,
        url: route,
        data: partData,
      });

      // Reset form state after successful submission
      setIsNewPartModal(false);
      setPartId(null);
    } catch (err) {
      console.error(err);
    } finally {
      // Always reset the loading state after the operation completes
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="part_name"
              placeholder="Desc." // from Excel
              required={true}
              onChange={handlePartData}
              value={partData?.part_name || ''}
            />
            <InputMuiCustom
              type="text"
              name="part_number"
              placeholder="Plating Part Number" // from Excel
              required={true}
              onChange={handlePartData}
              value={partData?.part_number || ''}
            />
            <InputMuiCustom
              type="text"
              name="assly_part_number"
              placeholder="Assly Part No." // from Excel
              // required={true}
              onChange={handlePartData}
              value={partData?.assly_part_number || ''}
            />
            <InputMuiCustom
              type="text"
              name="appearance"
              placeholder="Appearance" // from Excel
              onChange={handlePartData}
              value={partData?.appearance || ''}
            />
            <InputMuiCustom
              type="text"
              name="plating_type"
              placeholder="Plating Type" // from Excel
              onChange={handlePartData}
              value={partData?.plating_type || ''}
            />
            <InputMuiCustom
              type="text"
              name="plating_thickness_requirement"
              placeholder="Plating Thickness Requirement" // from Excel
              onChange={handlePartData}
              value={partData?.plating_thickness_requirement || ''}
            />
            <InputMuiCustom
              type="text"
              name="thread_gauge_requirement_1"
              placeholder="Thread Gauge Requirement 1" // from Excel
              onChange={handlePartData}
              value={partData?.thread_gauge_requirement_1 || ''}
            />
            <InputMuiCustom
              type="text"
              name="thread_gauge_requirement_2"
              placeholder="Thread Gauge Requirement 2" // from Excel
              onChange={handlePartData}
              value={partData?.thread_gauge_requirement_2 || ''}
            />
            <InputMuiCustom
              type="text"
              name="thread_gauge_requirement_3"
              placeholder="Thread Gauge Requirement 3" // from Excel
              onChange={handlePartData}
              value={partData?.thread_gauge_requirement_3 || ''}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default EditModule;
