import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../../components/common/inputs/GridSelect';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';
import { Checkbox } from '@mui/material';
import { StyledNotesField } from '../../../components/common/FormControlMui';
import DescriptionTextfield from '../../../components/common/DescriptionNotes';
import AlertMui from '../../../components/common/AlertMui';
import { handleError } from '../../../utils/helpers/errorHandler';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    reciept_date: new Date(),
    category: '',
    action_implementation_status: '',
    qdcftsrm: '',
    applicability_and_action_am5_am6_applicabiliy: '',
    applicability_and_action_am5_am6_action: '',
    applicability_and_action_1085_kw_tsi_ind_applicabiliy: '',
    applicability_and_action_1085_kw_tsi_ind_action: '',
    applicability_and_action_am_370_applicabiliy: '',
    applicability_and_action_am_370_action: '',
    exclude_report: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer Complaint' : 'Create Customer Complaint'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alert, setAlert] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});

  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/lessonLearnRoute/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleModuleData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.customer_id) {
      setAlertMessage('Please enter Customer name');
      setAlert(true);
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);

    const apiCall = id
      ? axios.put(`/api/lessonLearnRoute/${id}`, data)
      : axios.post('/api/lessonLearnRoute', data);

    apiCall
      .then(() => {
        setIsLoading(false);
        setIsModal(false);
      })
      .catch((err) => {
        setIsLoading(false);

        // Handle field errors using the error handler
        handleError(
          err,
          setAlertMessage,
          setFieldErrors,
          setAlert, // Open alert
          {
            qdcftsrm:
              'Q- Quality, D- Delivery, C- Cost, F- Feature, T- Technology, S- Sustainability, R- Risk, M- Management',
            category:
              'Category- Customer/Warranty /Inhouse/ Development (TGW/TGR) / Supplier/ Kaizen, Continual Improvement / Competitors / Sister Plant / Field failure',
            action_implementation_status:
              'Action Implementation Status(open/closed) ',
          }
        );
      });
  };

  return (
    <>
      <Loader loader={isLoading} />
      <h1>PAST DEFECT HISTORY</h1>
      <GridInputs
        id={'plant'}
        name={'plant'}
        html_for={'plant'}
        label_name={'IAI Plant'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.plant || ''}
        onChange={handleModuleData}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'reciept_date'}
        value={data?.reciept_date || null}
        onChange={(date) => {
          data.reciept_date = date;
        }}
      />
      <GridSelect
        name="category"
        label_name="Category"
        html_for={'category'}
        value={data?.category || ''}
        onChange={handleModuleData}
      >
        {[
          'Customer',
          'Warranty',
          'Inhouse',
          'Development (TGW/TGR)',
          'Supplier',
          'Kaizen',
          'Continual Improvement',
          'Competitors',
          'Sister Plant',
          'Field failure',
        ].map((val) => (
          <MenuItem key={val} value={val}>
            {val}
          </MenuItem>
        ))}
      </GridSelect>
      <AutocompleteGridmui
        id={'customer_name'}
        label={'Customer'}
        option_name={'customer_name'}
        arrayofObj={customerData}
        value={data.customer_id || ''}
        onChange={(e, value) => {
          data.customer_id = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'family'}
        name={'family'}
        html_for={'family'}
        label_name={'Family'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.family || ''}
        onChange={handleModuleData}
      />

      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part No.'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {data?.part_id ? data?.part_id?.part_number : 'Pick Part'}
              </p>
            </Grid>

            {data.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.part_id = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>

      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Name'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {data?.part_id ? data?.part_id?.part_name : ''}
              </p>
            </Grid>
          </Grid>
        </div>
      </DivStyled>
      <h1>Responsibility: Plant CFT</h1>
      <StyledNotesField>
        <DescriptionTextfield
          name="defect_details"
          onChange={handleModuleData}
          value={data?.defect_details || ''}
          label={'Defect Details '}
        />
      </StyledNotesField>
      <GridInputs
        id={'repeated'}
        name={'repeated'}
        html_for={'repeated'}
        label_name={'Repeated/ New'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.repeated || ''}
        onChange={handleModuleData}
      />
      <GridInputs
        id={'qty'}
        name={'qty'}
        html_for={'qty'}
        label_name={'Qty.'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.qty || ''}
        onChange={handleModuleData}
      />

      <h1>Frequency of updating: 10th Every Month </h1>

      <StyledNotesField>
        <DescriptionTextfield
          name="root_cause_for_occ"
          onChange={handleModuleData}
          value={data?.root_cause_for_occ || ''}
          label={'Root Cause for Occurrence'}
        />
      </StyledNotesField>
      <StyledNotesField>
        <DescriptionTextfield
          name="root_cause_for_non_detection"
          onChange={handleModuleData}
          value={data?.root_cause_for_non_detection || ''}
          label={'Root Cause for Non Detection'}
        />
      </StyledNotesField>
      <StyledNotesField>
        <DescriptionTextfield
          name="corrective_action_for_occ"
          onChange={handleModuleData}
          value={data?.corrective_action_for_occ || ''}
          label={'Corrective Action of Occurrence'}
        />
      </StyledNotesField>
      <StyledNotesField>
        <DescriptionTextfield
          name="corrective_action_for_non_detection"
          onChange={handleModuleData}
          value={data?.corrective_action_for_non_detection || ''}
          label={'Corrective Action of Non Detection'}
        />
      </StyledNotesField>

      <GridSelect
        name="action_implementation_status"
        label_name="Action Implementation Status (open/closed) "
        html_for={'action_implementation_status'}
        value={data?.action_implementation_status || ''}
        onChange={handleModuleData}
      >
        <MenuItem value="Open">Open</MenuItem>
        <MenuItem value="Closed">Closed</MenuItem>
      </GridSelect>

      <StyledNotesField>
        <DescriptionTextfield
          name="lesson_learnt"
          onChange={handleModuleData}
          value={data?.lesson_learnt || ''}
          label={'Lessons Learnt'}
        />
      </StyledNotesField>
      <h1>APPLICABILITY & ACTION TAKEN UPDATED AS ON 10.06.2024 </h1>
      <GridSelect
        name="qdcftsrm"
        label_name="Q- Quality, D- Delivery, C- Cost, F- Feature, T- Technology, S- Sustainability, R- Risk, M- Management "
        html_for={'qdcftsrm'}
        value={data?.qdcftsrm || ''}
        onChange={handleModuleData}
      >
        {[
          'Quality',
          'Delivery',
          'Cost',
          'Feature',
          'Technology',
          'Sustainability',
          'Risk',
          'Management',
        ].map((val) => (
          <MenuItem key={val} value={val}>
            {val}
          </MenuItem>
        ))}
      </GridSelect>

      <h3>1 - AM5-AM6</h3>
      <GridSelect
        name="applicability_and_action_am5_am6_applicabiliy"
        label_name="Applicability 
(Y/N) "
        html_for={'applicability_and_action_am5_am6_applicabiliy'}
        value={data?.applicability_and_action_am5_am6_applicabiliy || ''}
        onChange={handleModuleData}
      >
        <MenuItem value="Y">Y</MenuItem>
        <MenuItem value="N">N</MenuItem>
      </GridSelect>
      <GridSelect
        name="applicability_and_action_am5_am6_action"
        label_name="Action Implemented  
(Y/N) "
        html_for={'applicability_and_action_am5_am6_action'}
        value={data?.applicability_and_action_am5_am6_action || ''}
        onChange={handleModuleData}
      >
        <MenuItem value="Y">Y</MenuItem>
        <MenuItem value="N">N</MenuItem>
      </GridSelect>
      <h3>2 - 1.0 85 KW TSI IND</h3>
      <GridSelect
        name="applicability_and_action_1085_kw_tsi_ind_applicabiliy"
        label_name="Applicability 
(Y/N) "
        html_for={'applicability_and_action_1085_kw_tsi_ind_applicabiliy'}
        value={
          data?.applicability_and_action_1085_kw_tsi_ind_applicabiliy || ''
        }
        onChange={handleModuleData}
      >
        <MenuItem value="Y">Y</MenuItem>
        <MenuItem value="N">N</MenuItem>
      </GridSelect>
      <GridSelect
        name="applicability_and_action_1085_kw_tsi_ind_action"
        label_name="Action Implemented  
(Y/N) "
        html_for={'applicability_and_action_1085_kw_tsi_ind_action'}
        value={data?.applicability_and_action_1085_kw_tsi_ind_action || ''}
        onChange={handleModuleData}
      >
        <MenuItem value="Y">Y</MenuItem>
        <MenuItem value="N">N</MenuItem>
      </GridSelect>
      <h3>3 - AM370 </h3>
      <GridSelect
        name="applicability_and_action_am_370_applicabiliy"
        label_name="Applicability 
(Y/N) "
        html_for={'applicability_and_action_am_370_applicabiliy'}
        value={data?.applicability_and_action_am_370_applicabiliy || ''}
        onChange={handleModuleData}
      >
        <MenuItem value="Y">Y</MenuItem>
        <MenuItem value="N">N</MenuItem>
      </GridSelect>
      <GridSelect
        name="applicability_and_action_am_370_action"
        label_name="Action Implemented  
(Y/N) "
        html_for={'applicability_and_action_am_370_action'}
        value={data?.applicability_and_action_am_370_action || ''}
        onChange={handleModuleData}
      >
        <MenuItem value="Y">Y</MenuItem>
        <MenuItem value="N">N</MenuItem>
      </GridSelect>
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART LIST"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
      {/* Alert message for general errors */}
      {alert && (
        <AlertMui
          alert={alert}
          message={alertMessage}
          setAlert={setAlert}
          severity={'warning'}
        />
      )}
    </>
  );
};

export default Create;
