import axios from 'axios';
import { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import {
  AddButton,
  CustomButton,
} from '../../../../components/common/button/CustomButtons';
import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
} from '../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../components/common/formatDate';
import { MdAssignmentTurnedIn } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface IData {
  _id?: string;
  store_part_id?: any;
  date_of_receiving?: Date;
  qty: number;
  balance_qty: string;
  isCompleted?: boolean;
  ri_store_location?: string;
  mrn_no?: string;
  vendor_id?: any;
  inspection_report_id?: any;
}
interface IProps {
  index: number;
  document: IData;
  deleteDocument: (id: string) => void;
  assignRegister: (id: string) => void;
  setId: any;
  setIsOpenPopup: any;
}

const ListTableRow = ({
  index,
  document,
  assignRegister,
  deleteDocument,
  setIsOpenPopup,
  setId,
}: IProps) => {
  const [totalPartQty, setTotalPartQty] = useState(0);
  const [isAssign, setIsAssign] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (document.store_part_id?._id) {
      axios
        .get(
          `/api/storeReceiveRegister/part/totalStoreQty/${document.store_part_id._id}`
        )
        .then((res) => {
          setTotalPartQty(res.data.totalStoreQty);
          if (
            res.data.totalStoreQty + document.qty >=
            document.store_part_id?.max_store_qty
          ) {
            setIsAssign(true);
          } else {
            setIsAssign(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [document]);
  return (
    <TableRow
      style={{
        background: isAssign ? '#8c0101' : '',
        color: isAssign ? 'white' : '',
      }}
    >
      <TableCell
        align="center"
        style={{
          color: isAssign ? 'white' : '',
        }}
      >
        {index + 1}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document.store_part_id?.ri_part_id?.part_number}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document?.mrn_no}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document?.ri_store_location}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {formatDate(document?.date_of_receiving || '')}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document?.vendor_id?.vender_name ||
          document?.inspection_report_id?.vendor_id ||
          ''}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document.qty}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {totalPartQty + (document.qty || 0)}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {!isAssign && (
          <AddIconButton
            tooltipTitle="Inspection Report"
            onClick={() => {
              navigate(
                `/common/inspectionReport/${
                  document?.store_part_id?.ri_part_id?._id || ''
                }/${document?.inspection_report_id?._id || ''}?partName=${
                  document?.store_part_id?.ri_part_id?.part_name || ''
                }&partNumber=${
                  document?.store_part_id?.ri_part_id?.part_number || ''
                }&isView=false&receiveResisterId=${document?._id}&vendorId=${
                  document?.vendor_id?.vender_name || ''
                }&qty=${document?.qty || ''}&mrn_no=${document?.mrn_no || ''}`
              );
            }}
          />
        )}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
          textTransform: 'capitalize',
          ...(document?.inspection_report_id?.is_admin_request ===
            'pending' && {
            backgroundColor: '#FDD835',
          }),
          ...(document?.inspection_report_id?.is_admin_request ===
            'accepted' && {
            backgroundColor: '#2b8a3e',
            color: '#fff',
          }),
          ...(document?.inspection_report_id?.is_admin_request ===
            'rejected' && {
            backgroundColor: '#f03e3e',
            color: '#fff',
          }),
          ...(document?.inspection_report_id?.is_admin_request === 'reject' && {
            backgroundColor: '#f03e3e',
            color: '#fff',
          }),
        }}
        align="center"
      >
        {document?.inspection_report_id?.is_admin_request === 'pending' &&
          'pending for approval'}
        {document?.inspection_report_id?.is_admin_request === 'accepted' &&
          'accepted'}
        {document?.inspection_report_id?.is_admin_request === 'rejected' &&
          'Sent back'}
        {document?.inspection_report_id?.is_admin_request === 'reject' &&
          'rejected'}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {/* {!isAssign && (
          <CustomButton
            color="primary"
            size={'small'}
            disabled={document.isCompleted || isAssign}
            sx={{
              margin: '10px',
            }}
            icon={<MdAssignmentTurnedIn />}
            onClick={() => assignRegister(document._id || '')}
          >
            ASSIGN
          </CustomButton>
        )} */}
        {/* <EditIconButton
          tooltipTitle="edit"
          style={{
            color: isAssign ? 'white' : '',
          }}
          onClick={() => {
            setId(document._id || '');
            setIsOpenPopup(true);
          }}
        /> */}
        <DeleteIconButton
          tooltipTitle="delete"
          style={{
            color: isAssign ? 'white' : '',
          }}
          onClick={() => deleteDocument(document._id || '')}
        />
      </TableCell>
    </TableRow>
  );
};

export default ListTableRow;
