import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveButton } from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import TController from './components/create/TController';
import useConfirm from '../../../../components/common/useConfirm';
import useGetData from './helpers/useGetData';
import { Typography } from '@mui/material';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';

interface MyData {
  moduleData: any;
  setModuleData: any;
  error?: string | null;
}
const Create = () => {
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const navigate = useNavigate();
  const is_competency_matrix =
    useQuery('is_competency_matrix') === 'true' ? true : false;
  const { error, moduleData, setModuleData }: MyData = useGetData(
    id,
    is_competency_matrix,
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.is_competency_matrix = true;
    await axios
      .put(`/api/employeeRoute/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (process_idx: string, rowIdx: number) => {
    let data = { ...moduleData };
    data.matrix[process_idx].splice(rowIdx, 1);
    setModuleData(data);
  };

  const deleteCompetencyMatrix = async () => {
    const ans = window.confirm(
      'Are you sure you want to permanently delete this Competency Matrix',
    );
    if (ans) {
      moduleData.job_description = {};
      if (moduleData._id) {
        moduleData.is_competency_matrix = false;
        await axios
          .put(`/api/employeeRoute/${moduleData._id}`, moduleData)
          .then((res) => {
            alert('delete successfully');
            navigate(-1);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  if (error) {
    return (
      <Typography textAlign={'center'} mt={5} variant={'h5'}>
        COMPETENC MATRIX IS STILL NOT APPROVED, PLEASE CONTACT YOUR HOD
      </Typography>
    );
  }

  return (
    <Box>
      <Box>
        <ChildHeader text="COMPETENCE MATRIX">
          {!isView && (
            <>
              <DeleteIconButton
                tooltipTitle="DELETE JOB CARD"
                onClick={() => {
                  deleteCompetencyMatrix();
                }}
              />
              <SaveButton
                onClick={() => handleSumbit()}
                label={is_competency_matrix ? 'Update' : 'Submit'}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, isView, handelDeleteRows }} />
      <DialogSubmit />
    </Box>
  );
};

export default Create;
