import { Stack } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import {
  DeleteIconButton,
  UploadImageIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';
import MonthDoubleClick from './MonthDoubleClick';

interface IProps {
  moduleData: { [key: string]: any };
  isView: boolean;
  title?: string;
  phase: any;
  setModuleData: any;
  uploadImageFile: any;
  handleRemarks: any;
}

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  height: '50px',
};
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: '#ffd166',
  color: '#11111',
} as const;

const stickStylesRow = {
  position: 'sticky',
  width: '100%',
  top: '0rem',
  border: '1px solid black',
  zIndex: '2',
  backgroundColor: '#4895ef',
} as const;

const TBody1 = ({
  handleRemarks,
  title,
  phase,
  moduleData,
  isView,
  setModuleData,
  uploadImageFile,
}: IProps) => {
  const [render, setRender] = useState(false);
  const handleDoubleClick = async (
    event: any,
    rowIdx: number,
    bool: boolean,
  ) => {
    if (event.detail === 2) {
      moduleData[phase][rowIdx].isRequired = bool;
      setRender((prev) => !prev);
    }
  };
  const weekLength = moduleData?.phase_1[0]?.months?.length / 4 || 0;
  const monthLength = moduleData?.phase_1[0]?.months?.length || 0;
  return (
    <tbody>
      <tr style={{ ...stickStylesRow, top: '0%' }}>
        <th
          rowSpan={3}
          style={{
            ...style1,
            ...stickStylesColumn,
            left: 0,
            minWidth: '50px',
          }}
        >
          S.N.
        </th>
        <th
          rowSpan={3}
          style={{
            ...style1,
            ...stickStylesColumn,
            left: 0,
            minWidth: '400px',
          }}
        >
          AQPQ ELEMENTS
        </th>
        {['MONTHS', 'REASON OF DELAY', 'REMARKS', 'UPLOAD PROOF'].map(
          (heading, index) => (
            <th
              key={index + 'heading'}
              rowSpan={heading === 'MONTHS' ? 1 : 3}
              colSpan={heading === 'MONTHS' ? monthLength : 1}
            >
              {heading}
            </th>
          ),
        )}
      </tr>
      <tr style={{ ...stickStylesRow, top: '3%', zIndex: 1 }}>
        {Array(weekLength)
          .fill(0)
          .map((_, index) => (
            <th colSpan={4}>
              {isView ? (
                formatDate(moduleData[`${phase}_months`]?.[index])
              ) : (
                <DatePickerMui
                  label=""
                  views={['year', 'month']}
                  format={'MM-YYYY'}
                  value={moment(moduleData[`${phase}_months`]?.[index] || null)}
                  onChange={(date) => {
                    const updatedMonths = [
                      ...(moduleData[`${phase}_months`] || []),
                    ];
                    updatedMonths[index] = date;
                    setModuleData((prevModuleData: any) => ({
                      ...prevModuleData,
                      [`${phase}_months`]: updatedMonths,
                    }));
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
          ))}
      </tr>
      <tr style={{ ...stickStylesRow, top: '9.8%', zIndex: 1 }}>
        {Array(weekLength)
          .fill({})
          .map(() => (
            <>
              <th>W1</th>
              <th>W2</th>
              <th>W3</th>
              <th>W4</th>
            </>
          ))}
      </tr>
      {moduleData[phase].map((item: any, index: number) => (
        <React.Fragment key={index}>
          <tr>
            <td
              rowSpan={2}
              style={{
                ...style1,
                ...stickStylesColumn,
                left: 0,
                minWidth: '50px',
              }}
            >
              {index + 1}
            </td>
            <td
              rowSpan={2}
              style={{
                ...style1,
                ...stickStylesColumn,
                left: 0,
                minWidth: '400px',
              }}
            >
              {item.activity}
            </td>
            {item.months.map((elem: any, monthIdx: number) => (
              <MonthDoubleClick
                key={monthIdx}
                planType="P"
                month="months"
                {...{ moduleData, monthIdx, phase, elem }}
                rowIdx={index}
              />
            ))}
            <td rowSpan={2} style={{ minWidth: '150px' }}>
              <TableInput
                isView={isView}
                name="reason_of_delay"
                type="text"
                value={item.reason_of_delay}
                onChange={(e) => {
                  item.reason_of_delay = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td rowSpan={2} style={{ minWidth: '150px' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td rowSpan={2} style={{ minWidth: '160px' }}>
              {moduleData._id ? (
                <Stack
                  direction="row"
                  sx={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <UploadImageIconButton
                    id={`${phase}-${index}-process-upload`}
                    tooltipTitle="upload"
                    sx={{}}
                    onChange={(e) => {
                      uploadImageFile(e, phase, index);
                    }}
                  />
                  {item.files?.length > 0 && (
                    <>
                      <ViewIconButton
                        tooltipTitle="view image"
                        onClick={() => {
                          window.open(
                            item.files[0].url,
                            '_blank',
                            'noreferrer',
                          );
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle={'delete'}
                        onClick={() => {
                          setRender((prev) => !prev);
                          item.files = [];
                        }}
                      />
                    </>
                  )}
                </Stack>
              ) : (
                <span>Submit only once to enable this field.</span>
              )}
            </td>
          </tr>

          <tr>
            {item.months.map((elem: any, monthIdx: number) => (
              <MonthDoubleClick
                planType="A"
                key={monthIdx}
                month="months"
                {...{ moduleData, monthIdx, phase, elem }}
                rowIdx={index}
              />
            ))}
          </tr>
        </React.Fragment>
      ))}
    </tbody>
  );
};

export default TBody1;
