import axios from 'axios';
import { useState, useEffect } from 'react';
import lodash from 'lodash';
import { npdTimePlanConstant } from './constants';

const useGetData = (projectId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    phase_1_employees_id: [],
    phase_2_employees_id: [],
    phase_3_employees_id: [],
    phase_4_employees_id: [],
    phase_5_employees_id: [],
    phase_1_employees: [],
    phase_2_employees: [],
    phase_3_employees: [],
    phase_4_employees: [],
    phase_5_employees: [],
    phase_months: [],
    phase_1_months: [],
    phase_2_months: [],
    phase_3_months: [],
    phase_4_months: [],
    phase_5_months: [],
    ...lodash.cloneDeep(npdTimePlanConstant),
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [useEffectRender, setUseEffectRender] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/EmployeeRoute/employee/customerForAutoComplete`)
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/npdTimePlanRoute/${projectId}`)
      .then(async (res) => {
        if (res.data?.msg === 'document not found') {
          const rawData = {
            phase_months: [],
            ...lodash.cloneDeep(npdTimePlanConstant),
            phase_1_employees_id: [],
            phase_2_employees_id: [],
            phase_3_employees_id: [],
            phase_4_employees_id: [],
            phase_5_employees_id: [],
            phase_1_employees: [],
            phase_2_employees: [],
            phase_3_employees: [],
            phase_4_employees: [],
            phase_5_employees: [],
            phase_1_remarks: [],
            phase_2_remarks: [],
            phase_3_remarks: [],
            phase_4_remarks: [],
            phase_5_remarks: [],
            phase_1_months: [],
            phase_2_months: [],
            phase_3_months: [],
            phase_4_months: [],
            phase_5_months: [],
          };
          setModuleData(rawData);
          return;
        }
        res.data.phase_1_employees = [];
        res.data.phase_2_employees = [];
        res.data.phase_3_employees = [];
        res.data.phase_4_employees = [];
        res.data.phase_5_employees = [];

        setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, [employee, useEffectRender]);
  return {
    moduleData,
    employee,
    setModuleData,
    isLoading,
    setIsLoading,
    setUseEffectRender,
    error,
  };
};

export default useGetData;
