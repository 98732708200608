import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../components/common';
import ApprovalSelect from './ApprovalSelect';
import formatDate from '../../../components/common/formatDate';
import { UpdateButton } from '../../../components/common/button/CustomButtons';
import { useNavigate } from 'react-router-dom';
import { ViewIconButton } from '../../../components/common/button/CustomIconButton';

interface IProps {
  handleChangeStatus: (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number,
  ) => void;
  updateStatus: (id: string, documentIndex: number) => void;
  tableData: {
    [key: string | number]: any;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'TYPE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'DEPARTMENT',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'DESIGNATION',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'STATUS',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'REPORT DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'UPDATE STATUS',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const TableList = ({ handleChangeStatus, updateStatus, tableData }: IProps) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">{document.type}</TableCell>
                <TableCell align="center">
                  {document?.department_id?.department_name ||
                    document?.area_of_work?.department_name}
                </TableCell>
                <TableCell align="center">
                  {document?.designation_id?.designation}
                </TableCell>
                <TableCell align="center">
                  <ApprovalSelect
                    {...{
                      handleChangeStatus,
                      document,
                      documentIndex,
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  {formatDate(document.date)}
                </TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() => {
                      if (document.model === 'competencymatrix') {
                        navigate(`/common/competencyCreate/${document._id}`);
                      }
                      if (document.model === 'jobdescription') {
                        navigate(`/common/JdCreate/${document._id}`);
                      }
                    }}
                  />
                  <UpdateButton
                    label="update"
                    size="small"
                    onClick={() => updateStatus(document._id, documentIndex)}
                  />
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </TableCustom>
    </React.Fragment>
  );
};

export default TableList;
