import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import { Loader } from '../../components/common/Loader';
import moment from 'moment';
import { StyledNotesField } from '../../components/common/FormControlMui';
import DescriptionTextfield from '../../components/common/DescriptionNotes';
import { useAuth } from '../../services/auth/AuthProvider';
import GridDatePicker from '../../components/common/inputs/GridDatePicker';

const Create = ({
  id,
  setIsModal,
  disable,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  disable: boolean;
}) => {
  const { state } = useAuth();
  const [data, setData] = useState<any>({});
  const [render, setRender] = useState(false);
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Kaizen' : 'Create Kaizen'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/kaizenSubmission/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      data.user_id = state?.user?.username;
      data.date_of_submission = new Date();
      data.status = 'Pending';
    }
  }, [id]);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    const formData = new FormData();

    for (const key in data) {
      if (data[key]) {
        if (key !== 'files') formData.append(key, data[key]);
      }
    }
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/kaizenSubmission/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/kaizenSubmission', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />

      <GridInputs
        id={'user_id'}
        name={'user_id'}
        html_for={'user_id'}
        label_name={'User Id:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.user_id || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridDatePicker
        label_name="Date Of Submission:"
        html_for={'date_of_submission'}
        value={data?.date_of_submission || null}
        onChange={(date: any) => {
          data.date_of_submission = date;
          setRender((prev) => !prev);
        }}
        disabled={disable}
      />
      <StyledNotesField>
        <DescriptionTextfield
          onChange={handleCustomerData}
          label={'Description'}
          value={data.description || ''}
        />
      </StyledNotesField>
      <StyledNotesField>
        <DescriptionTextfield
          onChange={handleCustomerData}
          label={'Benefit to the Company'}
          name="benefit"
          value={data.benefit || ''}
        />
      </StyledNotesField>
      {state?.user?.userType === 2 && (
        <>
          <StyledNotesField>
            <DescriptionTextfield
              onChange={handleCustomerData}
              label={'Kaizen Remarks'}
              name="kaizen_remarks"
              value={data?.kaizen_remarks || ''}
            />
          </StyledNotesField>
          <GridSelect
            name="status"
            label_name="STATUS:"
            html_for={'status'}
            value={data.status || ''}
            onChange={(e) => {
              data.status = e.target.value;
              setRender((prev) => !prev);
            }}
            disabled={disable}
          >
            <MenuItem disabled={disable} value="Pending">
              Pending
            </MenuItem>
            <MenuItem disabled={disable} value="Send Back for Improvement">
              Send Back For Improvement
            </MenuItem>
            <MenuItem disabled={disable} value="Approve">
              Approve
            </MenuItem>
          </GridSelect>
        </>
      )}

      {!disable && (
        <Grid item xs={3}>
          <CustomUploadButton
            lable={'UPLOAD DOCUMENT'}
            multiple
            accept="image/png,image/jpeg,image/jpg,application/pdf"
            onChange={(e) => setFiles(e.target.files)}
            // disabled={disable}
          />{' '}
        </Grid>
      )}
      {!disable && (
        <div
          className="btn_save"
          style={{
            position: 'absolute',
            right: '140px',
            top: '-0.6px',
          }}
        >
          <SubmitButton
            label={`${id ? 'update' : 'submit'}`}
            type="button"
            onClick={handleSumbit}
          />
        </div>
      )}
      <DialogSubmit />
    </>
  );
};

export default Create;
