interface IProps {
  moduleData: any;
  isView: boolean;
  project: string;
}

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  height: '50px',
};
const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: 1,
  height: '50px',
  backgroundColor: '#fcbf49',
  color: '#11111',
} as const;

const stickStylesRow = {
  position: 'sticky',
  width: '100%',
  top: '0rem',
  border: '1px solid black',
  zIndex: 2,
  backgroundColor: '#4F81BD',
} as const;
const THead = ({ moduleData, isView, project }: IProps) => {
  return (
    <thead>
      <tr style={{ height: '50px' }}>
        <th>PROJECT NAME.:</th>
        <th colSpan={5} align="left">
          {project}
        </th>
        <th colSpan={3}>MAJOR RESPONSIBILITY:</th>
        <td colSpan={3}>⊚︎</td>
        <th colSpan={3}>MINOR RESPONSIBILITY:</th>
        <td colSpan={3}>⏺︎</td>
      </tr>

      <tr style={{ ...stickStylesRow, top: '4.3%' }}>
        <th
          rowSpan={1}
          colSpan={1}
          style={{
            ...style1,
            ...stickStylesColumn,
            left: 0,
            minWidth: '125px',
          }}
        >
          S NO.
        </th>
        <th
          rowSpan={1}
          colSpan={2}
          style={{
            ...style1,
            ...stickStylesColumn,
            left: 125,
            minWidth: '300px',
          }}
        >
          APQP ELEMENTS
        </th>
        <th>MKTG.</th>
        <th>DEV</th>
        <th>QA</th>
        <th>PROD</th>
        <th>LAB</th>
        <th>PURCHASE</th>
        <th>PPC</th>
        <th>MAINT.</th>
        <th>STORE</th>
        <th>TOOL ROOM</th>
        <th colSpan={1} rowSpan={1}>
          ECN
        </th>
        <th colSpan={1} rowSpan={1}>
          LC
        </th>
        <th colSpan={1} rowSpan={1}>
          STATUS
        </th>
      </tr>
    </thead>
  );
};

export default THead;
