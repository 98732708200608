import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface ifeildType {
  id: string;
  option_name: string;
}

const AutocompleteMuiCustom = ({
  id,
  option_name,
  onChange,
  value,
  arrayofObj,
  label,
  disabled,
  sx,
  ...rest
}: {
  id: string;
  value: string | {};
  option_name: string;
  arrayofObj: [] | any;
  label?: string;
  variant?: 'standard';
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: any) => void;
  disabled?: boolean;
  sx?: any;
}) => {
  return (
    <>
      <Autocomplete
        disabled={disabled}
        id={id}
        options={arrayofObj}
        getOptionLabel={(option: any) => option?.[option_name] || ''}
        isOptionEqualToValue={(option: any, value: any) =>
          option?.[option_name] === value?.[option_name] || true
        }
        sx={sx}
        value={value}
        onChange={(e: any, value) => onChange(e, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            label={label}
            {...rest}
          />
        )}
      />
    </>
  );
};

export default AutocompleteMuiCustom;
